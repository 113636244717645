import { ApikeyModel, ApikeyInput } from '@/app/apikey';
import { Paginate } from '@/core/hooks';
import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';

export const apikeyApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		apikeyList: builder.query<ResponseListModel<ApikeyModel>, Paginate>({
			query: paginate => ({
				method: 'GET',
				url: 'apikey/',
				params: paginate,
			}),
		}),

		apikeyCreate: builder.mutation<ResponseModel<ApikeyModel>, ApikeyInput>({
			query: payload => ({
				method: 'POST',
				url: 'apikey/',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Api key created');
				} catch (error) {}
			},
		}),

		apikeyDelete: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `apikey/${id}`,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Api key deleted');
				} catch (error) {}
			},
		}),
	}),
});

export const {
	useApikeyListQuery,
	useApikeyCreateMutation,
	useApikeyDeleteMutation,
} = apikeyApi;
