import * as z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '@/app/auth/redux';
import { useEffect } from 'react';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import { Button } from '@mantine/core';
import toast from 'react-hot-toast';

const schema = z.object({
	username: z.string().min(1, 'Username is required'),
	password: z.string().min(1, 'Password required'),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
	const navigate = useNavigate();

	const [onLogin, { isLoading, isSuccess,}] = useLoginMutation();

	useEffect(() => {
		if (isSuccess) navigate('/dashboard');
	}, [isSuccess, navigate]);

	return (
		<>
			<div>
				<h2 className='text-[32px] font-semibold text-primary/90'>Login</h2>

				<p className='mt-1 text-lg text-gray-500'>
					Don't have an account?{' '}
					<Link to='/signup' className='font-medium text-blue-600'>
						Sign Up
					</Link>
				</p>
			</div>

			<Form<LoginInput, typeof schema>
				schema={schema}
				onSubmit={payload => onLogin(payload)}
				className='mt-10'
			>
				{({ register, formState }) => (
					<>
						<div className='space-y-4'>
							<InputField
								type='text'
								label='Username'
								placeholder='username@123'
								focus
								loading={isLoading}
								error={formState.errors.username}
								registration={register('username')}

							/>

							<InputField
								type='password'
								label='Password'
								placeholder='********'
								loading={isLoading}
								error={formState.errors.password}
								registration={register('password')}

							/>

							<div className='flex items-center justify-between'>
								<label className='flex items-center space-x-2'>
									<input
										type='checkbox'
										className='form-control h-5 w-5 rounded-md'
									/>

									<span className='text-sm font-normal text-gray-500'>
										Remember me
									</span>
								</label>

								<Link to='#' className='text-sm font-medium text-blue-600'>
									Forgot password?
								</Link>
							</div>
						</div>

						<button
							type='submit'
							disabled={isLoading}
							className='mt-6 w-full font-semibold uppercase btn btn-primary'
						>
							Log In
						</button>
					</>
				)}
			</Form>
		</>
	);
};
