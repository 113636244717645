import {ResponseListModel, ResponseModel} from '@/core/types';
import {GroupModel} from "@/app/groups/types";
import {UserModel} from "@/app/user";
import toast from "react-hot-toast";
import {GroupAssignRoleInput} from "@/app/groups/components/GroupAssignRoles";
import baseApi from '@/core/lib/base';
import { CreateGroupInput } from '@/app/groups/components/GroupCreate';

export const groupsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        groupsList: builder.query<
            ResponseListModel<GroupModel>,{} >({
            query: () => ({
                method: 'GET',
                url: 'group',
                params: {
                    pageNumber: 0,
                    pageSize: 1500,
                },
            }),
            providesTags: ['Groups'],
        }),

        groupSingle: builder.query<
            GroupModel,{ id: string } >({
            query: (params) => ({
                method: 'GET',
                url: `group/${params.id}`,
            }),
        }),

        groupAssignRole: builder.mutation<ResponseModel<UserModel>, { data: GroupAssignRoleInput, id: string }>({
            query: payload => ({
                method: 'POST',
                url: '/group/assign-roles',
                body: {
                    groupId: payload.id,
                    roleIds: payload.data.roleIds
                },
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('User Group Assigned');
                } catch (error) {}
            },
            invalidatesTags: ['Groups']
        }),

        groupUnAssignRole: builder.mutation<ResponseModel<UserModel>, { data: GroupAssignRoleInput, id: string }>({
            query: payload => ({
                method: 'POST',
                url: '/group/unassign-roles',
                body: {
                    groupId: payload.id,
                    roleIds: payload.data.roleIds
                },
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('User Group UnAssigned Roles');
                } catch (error) {}
            },
            invalidatesTags: ['Groups']
        }),

        createGroup: builder.mutation<GroupModel, CreateGroupInput>({
            query: payload => ({
                method: 'POST',
                url: '/group',
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    // toast.success('Group Created');
                } catch (error) {}
            },
            invalidatesTags: ['Groups'],
        }),

        updateGroup: builder.mutation<ResponseModel<UserModel>, { data: CreateGroupInput, id: string }>({
            query: payload => ({
                method: 'PATCH',
                url: `/group/${payload.id}`,
                body: payload.data,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Group Updated');
                } catch (error) {}
            },
            invalidatesTags: ['Groups'],
        }),

        deleteGroup: builder.mutation<ResponseModel<UserModel>, { data: CreateGroupInput, id: string }>({
            query: payload => ({
                method: 'DELETE',
                url: `/group/${payload.id}`,
                body: payload.data,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Group Updated');
                } catch (error) {}
            },
            invalidatesTags: ['Groups'],
        }),
    }),
});

export const { useGroupsListQuery,useUpdateGroupMutation, useDeleteGroupMutation, useGroupUnAssignRoleMutation, useGroupAssignRoleMutation, useCreateGroupMutation, useGroupSingleQuery } = groupsApi;
