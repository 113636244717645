import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { useState, useEffect } from 'react';
import { toggleSidebar } from '@/store/themeConfigSlice';
import IconCaretsDown from '@/components/Icon/IconCaretsDown';
import IconCaretDown from '@/components/Icon/IconCaretDown';
import IconUsers from '@/components/Icon/IconUsers';
import IconServer from '@/components/Icon/IconServer';
import logo from '@/assets/images/logo.png';
import { useAppSelector } from '@/store';
import IconMenuDocumentation from '@/components/Icon/Menu/IconMenuDocumentation';
import IconMenuNotes from '@/components/Icon/Menu/IconMenuNotes';
import { BuildingOffice2Icon, DocumentChartBarIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/24/outline';
import IconPrinter from '@/components/Icon/IconPrinter';
import IconUserPlus from '@/components/Icon/IconUserPlus';

const Sidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { user } = useAppSelector((store) => store.userState);

    const navigation = [
        {
            name: 'Dashboard',
            to: '/dashboard',
            icon: IconServer,
            current: location.pathname === '/dashboard',
            roles: ['default-roles-fdi-ibm']
        },
        {
            name: 'Invoice',
            to: '/invoice',
            icon: IconMenuNotes,
            current: location.pathname === '/invoice',
            roles: ['find_invoice']
        },
        {
            name: 'Items',
            to: '/item',
            icon: IconPrinter,
            current: location.pathname === '/item',
            roles: ['find_item']
        },
        {
            name: 'Clients',
            to: '/client',
            icon: IconUserPlus,
            current: location.pathname === '/client',
            roles: ['view_client_credentials']
        },
        {
            name: 'Company',
            to: '/company',
            icon: BuildingOffice2Icon,
            current: location.pathname === '/company',
            roles: ['find_company']
        },
        {
            name: 'Customers',
            to: '/customer',
            icon: UsersIcon,
            current: location.pathname === '/customer',
            roles: ['find_customer']
        },
    ];

    useEffect(() => {
        if (window.innerWidth < 1024) {
            dispatch(toggleSidebar());
        }
    }, [location]);

    const [currentMenu, setCurrentMenu] = useState<string>('');

    const toggleMenu = (value: string) => {
        setCurrentMenu(oldValue => {
            return oldValue === value ? '' : value;
        });
    };


    return (
        <div className={'dark'}>
            <nav
                className={`sidebar capitalize fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300`}
            >
                <div className="bg-white dark:bg-[#113254] h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <div className="w-full  flex   flex-col  items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <img
                                  src={logo}
                                 alt={'fdi logo'} />
                            </div>
                        </div>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-white/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    <div className="h-10"></div>
                    <PerfectScrollbar className="h-[calc(100vh)] relative">
                        <ul className="relative  space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    {navigation
                                        .filter(v => v.roles.some(value => user?.role.includes(value)))
                                        .map((item, index) => (
                                        <li key={index} className="nav-item">
                                            <NavLink
                                                to={item.to}
                                                className={`group ${
                                                    item.current
                                                        ? 'active text-white'
                                                        : ' '
                                                }`}
                                            >
                                                <div className="flex items-center">
                                                    <item.icon className="group-hover:!text-white  shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-white/80 dark:group-hover:text-white">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </NavLink>
                                        </li>
                                    ))}
                                    { user?.role.includes('find_users') && ItemDropDown(currentMenu, toggleMenu, {
                                        name: 'Users',
                                        Icon: UserGroupIcon,
                                        items: [{
                                            name: 'Users',
                                            to: '/user',
                                        },
                                            {
                                                name: 'Groups',
                                                to: '/groups',
                                            },],
                                    })}
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;

type DropDownProps = {
    items: {
        name: string;
        to: string;
    }[];
    name: string;
    Icon: React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
        title?: string | undefined;
        titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
    >;
};

function ItemDropDown(
    currentMenu: string,
    toggleMenu: (value: string) => void,
    item: DropDownProps
) {
    return (
        <li className="menu nav-item">
            <button
                type="button"
                className={`${
                    currentMenu === item.name ? '' : ''
                } nav-link group w-full`}
                onClick={() => toggleMenu(item.name)}
            >
                <div className="flex items-center">
                    <item.Icon className="group-hover:!text-white shrink-0" />
                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-white/80 dark:group-hover:text-white">
                        {item?.name}
                    </span>
                </div>

                <div
                    className={
                        currentMenu !== item.name
                            ? 'rtl:rotate-90 -rotate-90'
                            : ''
                    }
                >
                    <IconCaretDown />
                </div>
            </button>

            <AnimateHeight
                duration={300}
                height={currentMenu === item.name ? 'auto' : 0}
            >
                <ul className="sub-menu text-white/80">
                    {item.items.map((item, index) => (
                        <li key={index}>
                            <Link to={item.to}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </AnimateHeight>
        </li>
    );
}
