import { Link, useParams } from 'react-router-dom';
import { useGroupSingleQuery, useGroupsListQuery } from '@/app/groups/api';
import React, { Fragment } from 'react';
import { GroupAssignRole } from '@/app/groups/components/GroupAssignRoles';
import { Tab } from '@headlessui/react';
import { UserPlusIcon, UserMinusIcon } from '@heroicons/react/24/outline';
import { GroupUnAssignRole } from '@/app/groups/components/GroupUnAssignRoles';
import { FiUserX } from 'react-icons/fi';
import { UpdateGroup } from '@/app/groups/components/updateGroup';

export const EditGroup = () => {
    const { id } = useParams();
    const { data: all, isLoading } = useGroupsListQuery({ });
    const data = all?.list.filter((u) => u.id === id)[0];

    const tabs = [
        {
            icon: UserPlusIcon,
            title: 'Assign Roles',
            selected: true,
        },
        {
            icon: UserMinusIcon,
            title: 'Un-Assign Roles',
            selected: true,
        },
        {
            icon: FiUserX,
            title: 'Update Group',
            selected: true,
        },
    ];

    const tabItems = [
        <GroupAssignRole group={data || null} />,
        <GroupUnAssignRole group={data || null} />,
        <UpdateGroup group={data || null} />
    ];


    return isLoading ? <div className={'w-full h-[300px] flex justify-center items-center'}>
        <div className="w-10">
                <span
                    className="animate-spin border-4 border-primary border-l-transparent rounded-full w-5 h-5 inline-block align-middle m-auto mb-10"></span>
        </div>
    </div> : (
        <div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <Link
                        to="/groups"
                        className="text-primary hover:underline"
                    >
                        Groups
                    </Link>
                </li>
                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>{data?.name}</span>
                </li>
            </ul>

            <div className="w-full p-4 shadow-md mt-5 bg-white dark:bg-slate-800 rounded-md">
                <Tab.Group>
                    <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                        {tabs.map((item, index) => (
                            <Tab key={index} as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${
                                            selected
                                                ? '!border-white-light hover:text-gray-100 !border-b-white text-primary !outline-none dark:text-white dark:!border-[#39437b] dark:!border-b-black'
                                                : 'dark:text-gray-400'
                                        }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-gray-300`}
                                    >
                                        <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                        {item.title}
                                    </button>
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels>
                        {tabItems.map((item, index) => (
                            <Tab.Panel key={index}>
                                <div className="">{item}</div>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}
