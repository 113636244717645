import * as z from 'zod';
import { useEffect } from 'react';
import {Form} from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { InputField } from '@/components/input';
import toast from 'react-hot-toast';
import { useCreateClientMutation } from '@/app/clients/api';
import { Modal } from '@/components/modal/modal';

const schema = z.object({
    clientId: z.string().min(1, { message: 'ClientId is required' }),
});

export type CreateClientInput = z.TypeOf<typeof schema>;

export const CreateClient = (props: { open: boolean, onClose: () => void  }) => {
    const [onUpdate, { isLoading, isSuccess, isError, error }] = useCreateClientMutation();

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    return (
        <Modal  title='Create Client' onClose={props.onClose} opened={props.open}>
            <Form<CreateClientInput, typeof schema>
                schema={schema}
                onSubmit={payload => onUpdate(payload)}
            >
                {({ register, formState }) => (
                    <>
                        <InputField
                            type='text'
                            label='Client ID'
                            placeholder=''
                            loading={isLoading}
                            error={formState.errors.clientId}
                            registration={register('clientId')}
                        />

                        <button type='submit' disabled={isLoading} className='btn btn-primary  mt-6 w-full'>
                            <BookmarkIcon className='icon-md w-4' /> Create Client
                        </button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
