import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { usePaginate } from '@/core/hooks';
import {useRolesListQuery} from "@/app/roles/api";
import {RoleModel} from "@/app/roles/types";
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { useState } from 'react';
import { CreateRole } from '@/app/roles/components/RoleCreate';
import { useAppSelector } from '@/store';

export const RolesList = () => {
	const paginate = usePaginate();

    const { user } = useAppSelector((store) => store.userState);

	const { isLoading, data } = useRolesListQuery({
		...paginate.paginate,
	});


	const columns: TableColumn<RoleModel>[] = [
		{
			accessor: 'name',
			title: 'Role Name',
			render: row => row.name,
		},
		{
			accessor: 'description',
			title: 'Description',
			render: row => row.name
		},
	];

    const [open, setOpen] = useState(false);

	return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Roles</span>
                </li>
            </ul>
            <CreateRole open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Roles</h1>
                {user?.role.includes('create_roles') && <div onClick={() => setOpen(true)}
                                                             className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New Role</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                columns={columns}
                total={data?.total ?? 0}
                tableName="data"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<></>}
            />

            <Outlet />
        </>
    );
};
