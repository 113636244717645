import { Link, Outlet } from 'react-router-dom';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

import { format } from '@/utils';
import { usePaginate } from '@/core/hooks';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { Header } from '@mantine/core';
import { ApikeyCreate, ApikeyModel, useApikeyDeleteMutation, useApikeyListQuery } from '@/app/apikey';
import Confirmation from '@/components/confirmation';
import { useAppSelector } from '@/store';
import { useState } from 'react';

export const ApikeyList = () => {
	const paginate = usePaginate();

    const { user } = useAppSelector((store) => store.userState);

	const { isLoading, data } = useApikeyListQuery({ ...paginate.paginate });
	const [onDelete, { isLoading: isLoadingDelete }] = useApikeyDeleteMutation();

	const columns: TableColumn<ApikeyModel>[] = [
		{
			accessor: 'description',
			title: 'Description',
			render: row => row.description,
		},
		{
			accessor: 'token',
			title: 'Token',
			render: row => row.token,
		},
		{
            accessor: 'active',
			title: 'Active',
			render: row => (row.isActive ? 'Yes' : 'No'),
		},
		{
            accessor: 'lastUsed',
			title: 'Last used',
			render: row => format.dateTime(row.updated_at),
		},
		{
            accessor: 'created',
			title: 'Created',
			render: row => format.dateTime(row.created_at),
		},
		{
            accessor: ' ',
			title: '',
			render: row => (
				<div className='flex justify-end gap-2'>
					<Confirmation
                        title={'Delete Apikey'}
						loading={isLoadingDelete}
						onConfirm={() => onDelete({ id: row.id })}
					>
						{({ onOpen }) => (
							<button className={'btn btn-sm btn-danger'} onClick={onOpen}>
								<TrashIcon className='icon-sm w-4' />
							</button>
						)}
					</Confirmation>
				</div>
			),
		},
	];

    const [open, setOpen] = useState(false);

	return (
		<>
            <ApikeyCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title="Users">
                <h1 className={'p-4 text-lg'}>Api Keys</h1>
                {user?.role.includes('create_apikey') && <div onClick={() => setOpen(true)}
                                                             className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New Api Key</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Api Keys"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<div />}
            />

            <Outlet />
        </>
    );
};
