import * as z from 'zod';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { RraCodeModel, useRraCodeListQuery } from '@/app/rra';
import { useItemCreateMutation } from '@/app/item/api';
import { Modal } from '@/components/modal/modal';
import { Form } from '@/components/form';
import SelectField from '@/components/select/SelectField';
import { InputField } from '@/components/input';
import toast from 'react-hot-toast';

const schema = z.object({
	name: z.string().min(1, 'Item name required'),
	classificationId: z.string().min(1, 'Item classification required'),
	itemTypeId: z.string().min(1, 'Item type required'),
	countryId: z.string().min(1, 'Origin national country required'),
	packagingUnitId: z.string().min(1, 'Packaging unit required'),
	quantityUnitId: z.string().min(1, 'Quantity unit required'),
	taxTypeId: z.string().min(1, 'Tax type required'),
	unitPrice: z.string().min(1, 'Unit price required'),
	insurance: z.string().min(1, 'insurance required'),
	isItemUsed: z.string().min(1, 'Is item used required'),
});

export type ItemInput = z.TypeOf<typeof schema>;

export const ItemCreate = (props: { open: boolean, onClose: () => void }) => {
	const { data: itemCodes } = useRraCodeListQuery({ code_type: 'ITEMCODE' });

	const { data: itemTypes } = useRraCodeListQuery({
		code_type: 'ITEMTYPE',
	});

	const { data: countries } = useRraCodeListQuery({
		code_type: 'COUNTRY',
	});

	const { data: packageUnits } = useRraCodeListQuery({
		code_type: 'PACKINGUNIT',
	});

	const { data: quantityUnits } = useRraCodeListQuery({
		code_type: 'QUANTITYUNIT',
	});

	const { data: taxTypes } = useRraCodeListQuery({
		code_type: 'TAXATIONTYPE',
	});

	const [onCreate, { isLoading, isSuccess, isError, error }] = useItemCreateMutation();

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

	const transformRraCode = (data: RraCodeModel[]) =>
		data?.map(code => {
			return {
				value: code.id,
				label : code.name,
			};
		});

	return (
		<Modal
            opened={props.open}
			title='New Item'
            size={'70%'}
			// loading={isLoading}
            className={'max-w-[80%]'}
			onClose={props.onClose}
		>
			<Form<ItemInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ register, formState }) => (
					<>
						<div className='space-y-4'>
                            <div className={'grid grid-cols-2 gap-2'}>
                                <InputField
                                    type='text'
                                    label='Item name'
                                    placeholder='Eg: Inyange'
                                    loading={isLoading}
                                    error={formState.errors.name}
                                    registration={register('name')}

                                />

                                <SelectField
                                    label='Item classification'
                                    options={transformRraCode(itemCodes ? itemCodes.list : [])}
                                    // loading={isLoading}
                                    error={formState.errors.classificationId}
                                    registration={register('classificationId')}

                                />
                            </div>

                            <div className={'grid grid-cols-2 gap-2'}>
                                <SelectField
                                    label='Item type code'
                                    options={transformRraCode(itemTypes ? itemTypes.list : [])}
                                    // loading={isLoading}
                                    error={formState.errors.itemTypeId}
                                    registration={register('itemTypeId')}

                                />

                                <SelectField
                                    label='Origin nation country'
                                    options={transformRraCode(countries ? countries.list : [])}
                                    // loading={isLoading}
                                    error={formState.errors.countryId}
                                    registration={register('countryId')}

                                />
                            </div>

                            <div className={'grid grid-cols-2 gap-2'}>
                                <SelectField
                                    label='Package unit'
                                    options={transformRraCode(
                                        packageUnits ? packageUnits.list : []
                                    )}
                                    // loading={isLoading}
                                    error={formState.errors.packagingUnitId}
                                    registration={register('packagingUnitId')}

                                />

                                <SelectField
                                    label='Quantity unit'
                                    options={transformRraCode(
                                        quantityUnits ? quantityUnits.list : []
                                    )}
                                    // loading={isLoading}
                                    error={formState.errors.quantityUnitId}
                                    registration={register('quantityUnitId')}

                                />
                            </div>

                            <div className={'grid grid-cols-2 gap-2'}>
                                <SelectField
                                    label='Taxation type'
                                    options={transformRraCode(taxTypes ? taxTypes.list : [])}
                                    // loading={isLoading}
                                    error={formState.errors.taxTypeId}
                                    registration={register('taxTypeId')}

                                />

                                <InputField
                                    type='number'
                                    label='Unit price'
                                    placeholder='Eg: 1000'
                                    loading={isLoading}
                                    error={formState.errors.unitPrice}
                                    registration={register('unitPrice')}

                                />
                            </div>

                            <div className={'grid grid-cols-2 gap-2'}>
                                <SelectField
                                    label='Insurance'
                                    options={[
                                        { value: '1', label : 'Yes' },
                                        { value: '0', label : 'No' },
                                    ]}
                                    // loading={isLoading}
                                    error={formState.errors.insurance}
                                    registration={register('insurance')}

                                />

                                <SelectField
                                    label='Is item used'
                                    options={[
                                        { value: '1', label : 'Yes' },
                                        { value: '0', label : 'No' },
                                    ]}
                                    // loading={isLoading}
                                    error={formState.errors.itemTypeId}
                                    registration={register('isItemUsed')}

                                />

                            </div>




						</div>

						<button type='submit' disabled={isLoading} className='btn btn-primary  mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4 mr-3' /> Create
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
