import { CompanyModel, CompanyInput } from '@/app/company';
import { ResponseListModel, ResponseModel } from '@/core/types';
import { Paginate } from '@/core/hooks';
import { toast } from 'react-hot-toast';
import baseApi from '@/core/lib/base';

export const companyApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		companyListAll: builder.query<ResponseListModel<CompanyModel>, Paginate>({
			query: paginate => ({
				method: 'GET',
				url: 'company/',
				params: paginate,
			}),
            providesTags: ['CompaniesAll']
		}),

		companyListWithFilter: builder.query<
			ResponseListModel<CompanyModel>,
			Paginate
		>({
			query: paginate => ({
				method: 'GET',
				url: 'company',
				params: paginate,
			}),
            providesTags: ['CompaniesAll']
		}),

		companyCreate: builder.mutation<ResponseModel<CompanyModel>, CompanyInput>({
			query: payload => ({
				method: 'POST',
				url: 'company/',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Company created');
				} catch (error) {}
			},
            invalidatesTags: ['CompaniesAll']
		}),

		companyUpdateStatus: builder.mutation<
			ResponseModel<CompanyModel>,
			{ id: string }
		>({
			query: ({ id }) => ({
				method: 'PATCH',
				url: `company/${id}/verify`,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Company approved');
				} catch (error: any) {
                    toast.error(error.error.data.error || 'Error Occurred');
                }
			},
            invalidatesTags: ['CompaniesAll']
		}),

		companyDelete: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `company/${id}`,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Company deleted');
				} catch (error: any) {
                    toast.error(error.error.data.error || 'Error Occurred');
                }
			},
            invalidatesTags: ['CompaniesAll']
		}),

        companyVerify: builder.mutation<ResponseModel<null>, { id: string }>({
            query: ({ id }) => ({
                method: 'POST',
                url: `company/${id}/approve`,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Company Approved');
                } catch (error: any) {
                    toast.error(error.error.data.error || 'Error Occurred');
                }
            },
            invalidatesTags: ['CompaniesAll']
        }),
	}),
});

export const {
	useCompanyListAllQuery,
	useCompanyListWithFilterQuery,
	useLazyCompanyListWithFilterQuery,
	useCompanyCreateMutation,
	useCompanyUpdateStatusMutation,
	useCompanyDeleteMutation,
    useCompanyVerifyMutation,
} = companyApi;
