import baseApi from '@/core/lib/base';
import {ResponseListModel, ResponseModel} from '@/core/types';
import {UserModel} from "@/app/user";
import toast from "react-hot-toast";
import { ClientModel } from '@/app/clients/types';
import { CreateClientInput } from '@/app/clients/components/ClientCreate';

export const clientApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        clientsList: builder.query<
            ResponseListModel<ClientModel>,{} >({
            query: () => ({
                method: 'GET',
                url: 'client',
                params: {
                    pageNumber: 0,
                    pageSize: 1500,
                },
            }),
            providesTags: ['Clients'],
        }),

        createClient: builder.mutation<ResponseModel<UserModel>, CreateClientInput>({
            query: payload => ({
                method: 'POST',
                url: '/user/client/create',
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Client Created');
                } catch (error) {}
            },
            invalidatesTags: ['Clients'],
        }),
    }),
});

export const { useClientsListQuery, useCreateClientMutation } = clientApi;
