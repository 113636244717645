import * as z from 'zod';
import { useEffect } from 'react';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useCustomerCreateMutation } from '@/app/customer/redux';
import {useCompanyListAllQuery} from "@/app/company";
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import SelectField from '@/components/select/SelectField';
import toast from 'react-hot-toast';
import { Modal } from '@/components/modal/modal';

const schema = z.object({
	tin: z.string().min(1, 'tin required'),
	name: z.string().min(1, 'Name required'),
	phoneNumber: z.string().optional(),
	companyId: z.string().min(1, "Company is Required")
});

export type CustomerInput = z.TypeOf<typeof schema>;

export const CustomerCreate = (props: { open: boolean, onClose: () => void }) => {

	const [onCreate, { isLoading, isSuccess, isError, error }] = useCustomerCreateMutation();

	const { isLoading: companyIsLoading, data: companies } =
		useCompanyListAllQuery({
			pageNumber: 0,
			pageSize: 1000,
		});

	useEffect(() => {
		if (isSuccess) {
            props.onClose();
        }
	}, [isSuccess, props.onClose]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

	return (
		<Modal
            opened={props.open}
			title='New Customer'
			// loading={isLoading}
			onClose={props.onClose}
		>
			<Form<CustomerInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ register, formState }) => (
					<>
						<div className='space-y-4'>
							<InputField
								type='text'
								label='Tin'
								placeholder='Eg: 123456'
								loading={isLoading}
								error={formState.errors.tin}
								registration={register('tin')}

							/>

							<InputField
								type='text'
								label='Name'
								placeholder='Eg: john Doe'
								loading={isLoading}
								error={formState.errors.name}
								registration={register('name')}

							/>

							<InputField
								type='text'
								label='Phone number'
								placeholder='Eg: 2507 XXX XXX'
								loading={isLoading}
								error={formState.errors.phoneNumber}
								registration={register('phoneNumber')}

							/>

							<SelectField
								label='Company'
								options={
									companies
										? companies.list.map(company => {
											return { value: company.id, label : company.name };
										})
										: []
								}
								// loading={isLoading || companyIsLoading}
								error={formState.errors.companyId}
								registration={register('companyId')}

							/>
						</div>

						<button type='submit' disabled={isLoading} className='btn btn-primary mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4 mr-3' /> Create
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
