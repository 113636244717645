import { Modal } from '@/components/modal/modal';
import { UserCommonModel } from '@/app/user';
import { EnvelopeIcon, PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { UserAssignCompany } from '@/app/user/components/UserAssignGroup';
import toast from 'react-hot-toast';
import * as z from 'zod';
import { useGroupsListQuery } from '@/app/groups/api';

const schema = z.object({
    groupId: z.string().min(1, 'Group is required'),
});


export default function ViewUser(props: { open: boolean, onClose: () => void, user: UserCommonModel | null }) {
    const { user } = props;
    const [open , setOpen] = useState(false);

    return (
        <Modal size={'50%'} title={<h1 className={'text-lg font-semibold'}>{user?.firstName} {user?.lastName}</h1>} opened={props.open} onClose={props.onClose} >


            <div className="">
                <p className={'mb-3 text-sm'}>Personal Details</p>
                <div className="grid grid-cols-2 gap-3 mb-3">
                    <div className="flex items-center justify-start w-full flex-row gap-2">
                        <UserIcon className={'w-10 h-4'} />
                        <div className="flex flex-col">
                            <p className={'text-xs'}>First Name</p>
                            <p className={''}>
                                {user?.firstName}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-start w-full flex-row gap-2">
                        <UserIcon className={'w-10 h-4'} />
                        <div className="flex flex-col">
                            <p className={'text-xs'}>Last Name</p>
                            <p className={''}>
                                {user?.lastName}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-start w-full flex-row gap-2">
                        <UserIcon className={'w-10 h-4'} />
                        <div className="flex flex-col">
                            <p className={'text-xs'}>Username</p>
                            <p className={''}>
                                {user?.username}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-start w-full flex-row gap-2">
                        <EnvelopeIcon className={'w-10 h-4'} />
                        <div className="flex flex-col">
                            <p className={'text-xs'}>Email</p>
                            <p className={''}>
                                {user?.email}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
