import { userSlice } from '@/app/user';
import baseApi from '@/core/lib/base';
import { storage } from '@/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';

export const Logout = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		storage.removeToken();

		dispatch(baseApi.util.resetApiState());
		dispatch(userSlice.actions.logout());

		navigate('/login');
	}, [navigate, dispatch]);

	return null;
};
