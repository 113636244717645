import { useState } from 'react';

export type Paginate = {
	pageNumber: number;
	pageSize: number;
};

export type PaginateType = {
	paginate: Paginate;
	onPageNumber: (value: number) => void;
	onPageSize: (value: number) => void;
};

export const usePaginate = () => {
	const [paginate, setPaginate] = useState({
		pageNumber: 0,
		pageSize: 10,
	});

	const onPageNumber = (value: number) =>
		setPaginate(prev => {
			return { ...prev, pageNumber: value };
		});

	const onPageSize = (value: number) =>
		setPaginate(prev => {
			return { ...prev, pageSize: value };
		});

	return { paginate, onPageNumber, onPageSize };
};
