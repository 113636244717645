import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { TimeChartTransfer } from '@/app/dashboard/components';
import { useGetMonthQuery, useGetStatsQuery, useGetWeekQuery, useGetYearQuery } from '@/app/dashboard/api';
import { format } from '@/utils';
import { useAppSelector } from '@/store';


export function MainDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Dashboard |'));
    });

    const { data, isLoading} = useGetStatsQuery({});

    const { data: month } = useGetMonthQuery({ year: '2' });
    const { data: year } = useGetYearQuery({});
    const { data: week } = useGetWeekQuery({});


    const now = Date.now();
    const yearString = new Date(now).getFullYear().toString();

    const [yNum, setYNum] = useState(yearString);

    const { user } = useAppSelector(state => state.userState);

    return (
        <div className="">
            <div className="my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link
                            to="/dashboard"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                </ul>
                {user?.role.includes('view_report') && <>
                    <div className="py-4 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {isLoading ? [1, 1, 1, 1].map((item, index) => (
                            <div key={index} className="mb-5 w-full flex items-center justify-center">
                                <div
                                    className="max-w-[20rem] h-36 w-full bg-white/20 animate-pulse shadow-md rounded border border-white-light dark:border-0 dark:bg-primary-dark-light dark:shadow-none p-5">
                                    <div className="text-center font-semibold">
                                    </div>
                                </div>
                            </div>
                        )) : <>
                            <div className="mb-5 w-full flex items-center justify-center">
                                <div
                                    className="max-w-[20rem] w-full bg-primary shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-0 dark:bg-primary-dark-light dark:shadow-none p-5">
                                    <div className="text-center font-semibold">
                                        <h5 className="text-white text-xl font-bold mb-5 dark:text-white-light">Users</h5>
                                        <p className="text-white mb-5 text-2xl">{data?.users.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-5 w-full flex items-center justify-center">
                                <div
                                    className="max-w-[20rem] w-full bg-yellow-600 shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-0 dark:bg-yellow-600 dark:shadow-none p-5">
                                    <div className="text-center font-semibold">
                                        <h5 className="text-white text-xl font-bold mb-5 dark:text-white-light">Invoices</h5>
                                        <p className="text-white mb-5 text-2xl">{data?.invoice.approvedInvoice.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-5 w-full flex items-center justify-center">
                                <div
                                    className="max-w-[20rem] w-full bg-green-500 shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-0 dark:bg-green-500 dark:shadow-none p-5">
                                    <div className="text-center font-semibold">
                                        <h5 className="text-white text-xl font-bold mb-5 dark:text-white-light">Customers</h5>
                                        <p className="text-white mb-5 text-2xl">{data?.customer.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-5 w-full flex items-center justify-center">
                                <div
                                    className="max-w-[20rem] w-full bg-red-600 shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-0 dark:bg-red-700 dark:shadow-none p-5">
                                    <div className="text-center font-semibold">
                                        <h5 className="text-white text-xl font-bold mb-5 dark:text-white-light">Companies</h5>
                                        <p className="text-white mb-5 text-2xl">{data?.company.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>

                        </>
                        }
                    </div>

                    <div>
                        <TimeChartTransfer
                            monthData={month ?? []}
                            yearData={year ?? []}
                            weekData={week ?? []}
                            setYear={setYNum}
                            year={yNum} />
                    </div>
                </>}
            </div>
        </div>
    )
}
