import { useInvoiceReadQuery } from '@/app/invoice/redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { PrinterIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import rra_image from "@/assets/images/rra.png";
import mark from "@/assets/images/mark.png";
import {InvoiceModel} from "@/app/invoice";
import { Button, Header } from '@mantine/core';
import { LoaderIcon } from 'react-hot-toast';

export const InvoiceRead = () => {
	const { invoiceId } = useParams<{ invoiceId: string }>();

	const { isLoading, isError, data } = useInvoiceReadQuery({
		id: invoiceId ?? '',
	});

	const invoiceRef = useRef<HTMLDivElement>(null);

	const handlePrintInvoice = useReactToPrint({
		content: () => invoiceRef.current,
	});

	if (isError) return <Navigate to='/invoice' />;

	return isLoading ? (
        <div className={'w-full h-[300px] flex justify-center items-center'}>
            <div className="w-10">
                <span
                    className="animate-spin border-4 border-primary border-l-transparent rounded-full w-5 h-5 inline-block align-middle m-auto mb-10"></span>
            </div>
        </div>
    ) : (
        <>
            <div title={`Invoice #${data?.invoice_no}`} className={'flex flex-row justify-between items-center'}>
                <button
                    className={'btn btn-sm btn-primary gap-2'}
					onClick={handlePrintInvoice}
				>
					<PrinterIcon className='icon-sm w-4' />
					Print to PDF
				</button>

				<Link to='/invoice' className='btn btn-sm btn-primary'>
					<ArrowLeftIcon className='icon-sm w-4 mr-3' />
					Back
				</Link>
			</div>

			<div ref={invoiceRef}>
				<InvoiceCom data={data} />
			</div>
		</>
	);
};

export function InvoiceCom(props: { data: InvoiceModel | undefined }) {
	const { data  } = props
	return (
		<div className='mt-6 space-y-8 bg-white p-6'>
			<div>
				<div className='flex w-full flex-col gap-2 md:flex-row md:items-center md:justify-between md:gap-6'>
					<div className="flex flex-row w-full  justify-between">
						<div className="flex flex-row gap-3">
							{data?.status === 'approved' && (
								<img
									alt='qr-code'
									src={rra_image}
									className='w-28 object-cover'
								/>
							)}

							<div className='mt-2 space-y-1 text-left text-base font-normal'>
								<p>
                                        <span className='font-semibold uppercase'>
                                            {data?.company.name}
                                        </span>
								</p>

								<p>
									<span className='font-semibold uppercase'>TEL</span>:{' '}
									{data?.user?.phone}
								</p>

								<p>
									<span className='font-semibold uppercase'>Email</span>:{' '}
									{data?.user?.email}
								</p>

								<p>
									<span className='font-semibold'>TIN</span>:{' '}
									{data?.company.tin}
								</p>
							</div>
						</div>

						<img
							alt='qr-code'
							src={mark}
							className='w-28 object-cover'
						/>
					</div>
				</div>

				<div className="flex mt-4 w-full flex-row justify-between">
					<div className="flex flex-col gap-2 w-full">
						<p className='text-left text-base font-semibold uppercase'>
							Invoice To
						</p>
						<div className="flex flex-row justify-between w-full">
							<div className='flex flex-col'>
								<div
									className='mt-2 space-y-1 border border-black p-4 text-left text-base font-normal'>
									<p>
										<span className='font-semibold'>TIN</span>:{' '}
										{data?.customer?.tin}
									</p>

									<p>
										<span className='font-semibold'>Name</span>:{' '}
										{data?.customer?.name}
									</p>
								</div>
							</div>

							<div className='space-y-1 border border-black p-4 text-left text-base font-normal'>
								<p>
									<span className='font-semibold uppercase'>Invoice No</span>:{' '}
									{data?.invoice_no}
								</p>

								<p>
									<span className='font-semibold'>Date</span>:{' '}
									{dayjs(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
								</p>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className='overflow-x-auto'>
				<table className='w-full table-auto border border-black overflow-hidden whitespace-nowrap'>
                    <tbody>
                    <tr className={'bg-white font-semibold'}>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Item code
                        </th>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Item description
                        </th>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Quantity
                        </th>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Tax
                        </th>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Unit price
                        </th>
                        <th className="border border-black py-3 px-2 text-left text-sm font-semibold uppercase text-dark">
                            Total price
                        </th>
                    </tr>
                    </tbody>

                    <tbody className="border-b-[1px] border-black">
                    {data?.invoiceItems.map((item, key) => (
                        <tr key={key} className={'border-b border-black'}>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {item.item.code}
                            </td>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {item.item.name}
                            </td>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {item.quantity}
                            </td>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {item.item.tax_type.code}
                            </td>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {format.currency(item.unit_price)}
                            </td>
                            <td className="border-x border-black py-3 px-2 text-left text-sm font-normal text-dark">
                                {format.currency(item.quantity * item.unit_price)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className='flex gap-4 flex-row items-start justify-between'>
				<div className='space-y-1 text-left text-base font-normal'>
					{data?.status === 'approved' && (
						<>
							<p>
								<span className='font-semibold'>SDC INFORMATION</span>
							</p>
							<div className="font-bold">----------------------------------</div>
							<p>
								<span className='font-semibold'>Date</span>:{' '}
								{dayjs(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
							</p>
							<p>
								<span className='font-semibold'>SDC ID</span>:{' '}
								{data?.response?.data.sdcId}
							</p>
							<p>
								<span className='font-semibold'>Receipt Number</span>:{' '}
								{data?.response?.data.rcptNo}
							</p>
							<p>
								<span className='font-semibold'>Internal Data</span>:{' '}
								{data?.response?.data.intrlData}
							</p>
							<p>
								<span className='font-semibold'>Receipt Signature</span>:{' '}
								{data?.response?.data.rcptSign}
							</p>
							<div className="font-bold">----------------------------------</div>
							<p>
								<span className='font-semibold'>Receipt Number</span>:{' '}
								{data?.response?.data.rcptNo}
							</p>
							<p>
								<span className='font-semibold'>Date</span>:{' '}
								{dayjs(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
							</p>
							<p>
								<span className='font-semibold'>MRC</span>:{' '}
								{data?.response?.data.mrcNo}
							</p>
							<div className="font-bold">----------------------------------</div>
							<p>Powered by EBM v2</p>
						</>
					)}
				</div>

				<div>
					{data?.status === 'approved' && (
						<img
							alt='qr-code'
							src={`https://quickchart.io/qr?text=${encodeURIComponent(JSON.stringify(data?.response?.data))}`}
							className='w-[9rem] object-cover'
						/>
					)}
				</div>

				<div className="flex flex-col items-start justify-start">
					<div className="overflow-x-auto">
						<table className="table-auto border-collapse border border-gray-800">
							<tbody>
							<tr>
								<td className="border border-black px-4 py-2 text-center">Total Rwf</td>
								<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_amount ?? 0)}</td>
							</tr>
							<tr>
								<td className="border border-black px-4 py-2 text-center">Total A-EX Rwf</td>
								<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_amount ?? 0)}</td>
							</tr>
							{/*<tr>*/}
							{/*	<td className="border border-black px-4 py-2 text-center">Total b-18% Rwf</td>*/}
							{/*	<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_taxable_amount ?? 0)}</td>*/}
							{/*</tr>*/}
							<tr>
								<td className="border border-black px-4 py-2 text-center">Total Tax - A</td>
								<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_tax_amount ?? 0)}</td>
							</tr>
							<tr>
								<td className="border border-black px-4 py-2 text-center">Total Tax Amount</td>
								<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_tax_amount ?? 0)}</td>
							</tr>
							{/* Add more rows as needed */}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

	);
}
