import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Error404 from '@/errors/4004Error';
import { useAppSelector } from '@/store';

type AuthGuardProps = {
    roles?: string[];
    element: ReactNode;
};

export const AuthGuard = (props: AuthGuardProps) => {
    const { roles = ['default-roles-fdi-ibm'], element } = props;

    const { user } = useAppSelector(state => state.userState);

    if (!user) return <Navigate to='/login'/>;
    if (!roles.some(value => user.role.includes(value))) return <Error404 />;

    return <>{element}</>;
};

type PublicGuardProps = {
    element: ReactNode;
};

export const PublicGuard = (props: PublicGuardProps) => {
    const { element } = props;

    const { user } = useAppSelector(state => state.userState);

    if (user) return <Navigate to='/dashboard' />;
    return <>{element}</>;
};

