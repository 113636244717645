import { useLazyCompanyListWithFilterQuery } from '@/app/company';
import {
	useInvoiceListQuery,
	useInvoiceApproveMutation,
	useInvoiceCancelMutation,
} from '@/app/invoice/redux';
import { InvoiceModel } from '@/app/invoice/types';
import { format } from '@/utils';
import {
	PlusIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { useAppSelector } from '@/store';
import Confirmation from '@/components/confirmation';
import { InvoiceCreate } from '@/app/invoice';

export const InvoiceList = () => {

	const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

	const { isFetching, data } = useInvoiceListQuery(queryParams);
	const [onApprove, { isLoading: isLoadingApprove }] =
		useInvoiceApproveMutation();
	const [onCancel, { isLoading: isLoadingCancel }] = useInvoiceCancelMutation();

	const [listCompany, { isLoading: companyIsLoading, data: companies }] =
		useLazyCompanyListWithFilterQuery();

	useEffect(() => {
		if (user?.role.includes('admin'))
			listCompany({
				pageNumber: 0,
				pageSize: 1000,
			});
		// eslint-disable-next-line
	}, [user]);

    const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        const updatedParams = { ...queryParams, ['companyId']: value };
        setQueryParams(updatedParams);

        const searchParams = new URLSearchParams(updatedParams);
        window.history.pushState(null, '', `?${searchParams.toString()}`);
    };


	const columns: TableColumn<InvoiceModel>[] = [
		{
            accessor: 'created_at',
			title: 'Issued At',
			render: row => format.dateTime(row.created_at),
		},
		{
			accessor: 'invoice_no',
			title: 'Invoice no',
			render: row => (
				<Link to={row.id} className='font-semibold text-blue-600'>
					#{row.invoice_no}
				</Link>
			),
		},
		{
			accessor: 'tax_rate',
			title: 'Tax rate',
			render: row => `${row.tax_rate}%`,
		},
		{
			accessor: 'total_item_count',
			title: 'total items',
			render: row => row.total_item_count,
		},
		{
			accessor: 'total_tax_amount',
			title: 'Tax amount',
			render: row => format.currency(row.total_tax_amount),
		},
		{
			accessor: 'total_amount',
			title: 'Total amount',
			render: row => format.currency(row.total_amount),
		},
		{
			accessor: 'customer.name',
			title: 'Customer',
			render: row => row.customer?.name,
		},
		{
			accessor: 'company.name',
			title: 'Company',
			render: row => row.company.name,
		},
		{
			accessor: 'status',
			title: 'Status',
			render: row => row.status.toUpperCase(),
		},
		{
            accessor: ' ',
			title: '',
			render: row =>
				row.status === 'pending' &&
				user?.role.includes('admin') && (
					<div className='flex justify-end gap-2'>
                        <Confirmation
                            title={'Cancel Invoice'}
                            loading={isLoadingApprove}
                            onConfirm={() => onApprove(row.id)}
                        >
                            {({ onOpen }) => (
                                <button title={'Cancel Invoice'} className={'btn btn-success btn-sm'} onClick={onOpen}>
                                    <XMarkIcon className='icon-sm w-4' />
                                </button>
                            )}
                        </Confirmation>

						<Confirmation
                            title={'Cancel Invoice'}
							loading={isLoadingCancel}
							onConfirm={() => onCancel(row.id)}
						>
							{({ onOpen }) => (
								<button title={'Cancel Invoice'} className={'btn btn-danger btn-sm'} onClick={onOpen}>
									<XMarkIcon className='icon-sm w-4' />
								</button>
							)}
						</Confirmation>
					</div>
				),
		},
	];

    const [open , setOpen] = useState(false);

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Invoices</span>
                </li>
            </ul>
            <InvoiceCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Invoices</h1>
                {user?.role.includes('create_invoice') && <div onClick={() => setOpen(true)}
                                                               className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New Invoice</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Invoices"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
                filterComponent={
                    <>
                        {!user?.role.includes('owner') && (
                            <select
                                name='company'
                                defaultValue=''
                                onChange={handleFilter}
                                disabled={companyIsLoading}
                                className='form-control'
                            >
                                <option value='' disabled hidden>
                                    Filter By Company
                                </option>

                                {companies?.list.map((company) => (
                                    <option key={company.id} value={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </>
                }
            />

            <Outlet />
        </>
    );
};
