import { useInvoiceReadGlobalQuery } from '@/app/invoice/redux';
import { useReactToPrint } from 'react-to-print';
import { PrinterIcon } from '@heroicons/react/24/outline';
import React, { useRef} from 'react';
import { useParams} from "react-router-dom";
import {InvoiceCom} from "@/app/invoice";

export default function InvoiceReadGlobal() {
    const { id } = useParams();

    return (
        <div className={'bg-gray-100 flex min-h-screen'}>
            <div className="w-full flex flex-col justify-start items-center bg-white mx-10 my-10">
                <div className="p-5 w-full">
                    { id && <InvoiceReadBox invoiceId={id} /> }
                </div>
            </div>
        </div>
    )
}

export const InvoiceReadBox = (props: { invoiceId: string }) => {
    const { invoiceId } = props;

    const { isFetching, data, isError } = useInvoiceReadGlobalQuery({
        id: invoiceId ?? '',
    });

    const invoiceRef = useRef<HTMLDivElement>(null);

    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    });


    return isFetching ? (
        <div className='flex w-full items-center justify-center'>
            <div className='text-center'>
                <h2 className='text-lg font-semibold uppercase text-dark'>
                    Loading ...
                </h2>
            </div>
        </div>
    ) : isError ? (
        <div className={'w-full min-h-[400px] flex justify-center items-center'}>
            No Invoice was found with id: {invoiceId}
    </div>) :  (
        <>
            <div className={'flex flex-row justify-between items-center'} title={`Invoice #${data?.invoice_no}`}>
                <button
                    className={'btn btn-md btn-primary gap-2'}
                    onClick={handlePrintInvoice}
                >
                    <PrinterIcon className='icon-sm w-4' />
                    Print to PDF
                </button>
            </div>

            <div ref={invoiceRef}>
                <InvoiceCom data={data} />
            </div>
        </>
    );
};
