import * as z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useLazyCustomerSearchByTinQuery } from '@/app/customer';
import { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ItemModel, useItemListQuery } from '@/app/item';
import { useInvoiceCreateMutation } from '@/app/invoice/redux';
import { format } from '@/utils';
import { useAppSelector } from '@/store';
import { InputField } from '@/components/input';
import { Button, Modal } from '@mantine/core';
import SelectField from '@/components/select/SelectField';
import toast from 'react-hot-toast';

type CustomerType = {
	loading: boolean;
	control: any;
	register: any;
	formState: any;
	setValue: any;
};

type ItemFieldProps = {
	index: number;
	loading: boolean;
	data: ItemModel[];
	control: any;
	register: any;
	formState: any;
	setValue: any;
	onRemove: () => void;
};

type ItemProps = {
	loading: boolean;
	control: any;
	register: any;
	formState: any;
	setValue: any;
};

type InvoiceSummaryProps = {
	control: any;
};

const Customer = (props: CustomerType) => {
	const { loading, control, register, formState, setValue } = props;

	const { user } = useAppSelector(state => state.userState);

	const tin = useWatch({ control, name: 'customer.tin' });

	const [onSearch, { isSuccess, data }] = useLazyCustomerSearchByTinQuery();

	useEffect(() => {
		if (tin && tin.length === 6)
			onSearch({ tin, company: user?.company?.id ?? '' });
		// eslint-disable-next-line
	}, [tin]);

	useEffect(() => {
		if (data?.data) {
			handleSetValue(data.data.name, data.data.phoneNumber);
		} else {
			handleSetValue('', '', false);
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSetValue = (
		name: string,
		phone: string,
		validate: boolean = true
	) => {
		setValue('customer.name', name, { shouldValidate: validate });
		setValue('customer.phone', phone, { shouldValidate: validate });
	};

	return (
		<div className='grid grid-cols-1'>
			<InputField
				type='number'
				label='Customer Tin'
				placeholder='Eg: 123456'
				loading={loading}
				error={formState.errors.customerTin}
				registration={register('customerTin')}

			/>
		</div>
	);
};

const ItemField = (props: ItemFieldProps) => {
	const {
		index,
		loading,
		data,
		control,
		register,
		formState,
		setValue,
		onRemove,
	} = props;

	const itemId = useWatch({ control, name: `items.${index}.itemId` });

	useEffect(() => {
		if (itemId) {
			const item = data.filter(v => v.id === itemId)[0];

			setValue(`items.${index}.quantity`, '1', { shouldValidate: true });
			setValue(`items.${index}.unitPrice`, item.unitPrice.toString(), {
				shouldValidate: true,
			});
		}
		// eslint-disable-next-line
	}, [itemId]);

	return (
		<div className='flex flex-col items-center gap-4 md:flex-row'>
			<SelectField
				label='Item'
				options={data.map(item => {
					return { value: item.id, label: item.name };
				})}
				loading={loading}
				error={formState.errors.items?.[index]?.itemId}
				registration={register(`items.${index}.itemId` as const)}
                className={''}
			/>

			<InputField
				type='number'
				label='Quantity'
				placeholder='Eg: 10'
				loading={loading}
				disabled={!itemId}
				error={formState.errors.items?.[index]?.quantity}
				registration={register(`items.${index}.quantity` as const)}

			/>

			<InputField
				type='number'
				label='Unit price'
				placeholder='Eg: 1000'
				loading={loading}
				disabled={!itemId}
				error={formState.errors.items?.[index]?.unitPrice}
				registration={register(`items.${index}.unitPrice` as const)}

			/>

			{index > 0 && (
				<Button
					size='sm'
					onClick={onRemove}
					className='px-2.5 py-2 bg-red-600'
				>
					<XMarkIcon className='h-5 w-5' />
				</Button>
			)}
		</div>
	);
};

const Item = (props: ItemProps) => {
	const { loading, control, register, formState, setValue } = props;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items',
		rules: { minLength: 1 },
	});

	const { data: items } = useItemListQuery({
		pageNumber: 0,
		pageSize: 1000,
	});

	useEffect(() => {
		handleAddField();
		// eslint-disable-next-line
	}, []);

	const handleAddField = () =>
		append({ itemId: '', quantity: '', unitPrice: '' });

	return (
		<>
			<div className='flex justify-between gap-4'>
				<p className='text-sm font-medium uppercase'>Items</p>

				<Link
					to='#'
					onClick={handleAddField}
					className='text-sm font-bold uppercase text-blue-600'
				>
					Add Item
				</Link>
			</div>

			{fields.map((_, key) => (
				<ItemField
					key={key}
					index={key}
					loading={loading}
					data={items?.list ?? []}
					control={control}
					register={register}
					formState={formState}
					setValue={setValue}
					onRemove={() => remove(key)}
				/>
			))}
		</>
	);
};

const InvoiceSummary = (props: InvoiceSummaryProps) => {
	const { control } = props;

	const items = useWatch({ control, name: 'items' });

	const total =
		items?.reduce(
			(a: number, b: any) => a + Number(b.quantity) * Number(b.unitPrice),
			0
		) ?? 0;

	return (
		<div className='space-y-1 text-right text-lg font-normal capitalize'>
			<p>
				<span className='font-semibold'>Subtotal</span>:{' '}
				{format.currency(total)}
			</p>
			<p>
				<span className='font-semibold'>Discount</span>: {format.currency(0)}
			</p>
			<p>
				<span className='font-semibold'>Total</span>: {format.currency(total)}
			</p>
		</div>
	);
};

const schema = z.object({
	customerTin: z
		.string(),
	items: z.array(
		z.object({
			itemId: z.string().min(1, 'Item required'),
			quantity: z.string().min(1, 'Quantity required'),
			unitPrice: z.string().min(1, 'Unit price required'),
		})
	),
});

export type 	InvoiceInput = z.TypeOf<typeof schema>;

export const InvoiceCreate = (props: { open: boolean, onClose: () => void }) => {
	const [onCreate, { isLoading, isSuccess, isError, error }] = useInvoiceCreateMutation();

	useEffect(() => {
		if (isSuccess) {
            props.onClose();
        }
	}, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);


	return (
		<Modal
            opened={props.open}
			size='xl'
			title='New Invoice'
			// loading={isLoading}
			onClose={props.onClose}
		>
			<Form<InvoiceInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ control, register, formState, setValue }) => (
					<>
						<div className='space-y-5'>
							<Customer
								loading={isLoading}
								control={control}
								register={register}
								formState={formState}
								setValue={setValue}
							/>

							<Item
								loading={isLoading}
								control={control}
								register={register}
								formState={formState}
								setValue={setValue}
							/>

							<InvoiceSummary control={control} />
						</div>

						<button type='submit' disabled={isLoading} className='btn btn-primary mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4 mr-3' /> Create
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
