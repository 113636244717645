import {UserModel, UserInput, UserCommonModel} from '@/app/user';
import { Paginate } from '@/core/hooks';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';
import {UserAssignGroupInput} from "@/app/user/components/UserAssignGroup";
import baseApi from '@/core/lib/base';

export const userApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		userList: builder.query<
			ResponseListModel<UserCommonModel>, any>({
			query: paginate => ({
				method: 'GET',
				url: 'user/',
				params: paginate,
			}),
            providesTags: ['Users'],
		}),

		userCreate: builder.mutation<ResponseModel<UserModel>, UserInput>({
			query: payload => ({
				method: 'POST',
				url: 'user/',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User created');
				} catch (error) {}
			},
            invalidatesTags: ['Users'],
		}),

		userAssignGroup: builder.mutation<ResponseModel<UserModel>, { data: UserAssignGroupInput, id: string }>({
			query: payload => ({
				method: 'POST',
				url: 'user/group',
				body: {
					userId: payload.id,
					groupId: payload.data.groupId
				},
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User Group Assigned');
				} catch (error: any) {
                    toast.error(error.message);
                }
			},
		}),

        userUnAssignGroup: builder.mutation<ResponseModel<UserModel>, { data: UserAssignGroupInput, id: string }>({
            query: payload => ({
                method: 'POST',
                url: 'user/unassign-group',
                body: {
                    userId: payload.id,
                    groupId: payload.data.groupId
                },
            }),
            invalidatesTags: ['Users'],
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('User Group Assigned');
                } catch (error: any) {
                    toast.error(error.message);
                }
            },
        }),

		userUpdateCompany: builder.mutation<
			ResponseModel<UserModel>,
			{ id: string; payload: { companyId: string } }
		>({
			query: ({ id, payload }) => ({
				method: 'PATCH',
				url: `user/${id}/company`,
				body: payload,
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User company updated');
				} catch (error) {}
			},
		}),

		userUpdateStatus: builder.mutation<
			ResponseModel<UserModel>,
			{ id: string; status: boolean }
		>({
			query: ({ id, status }) => ({
				method: 'PATCH',
				url: `user/${id}/status`,
				body: { status },
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success(`User ${args.status ? `approved` : `de-approved`}`);
				} catch (error) {}
			},
		}),

		userUpdatePassword: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'PATCH',
				url: `user/${id}/password`,
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User password reseted');
				} catch (error) {}
			},
		}),

		userDelete: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `user/${id}`,
			}),
            invalidatesTags: ['Users'],
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('User deleted');
				} catch (error) {}
			},
		}),
	}),
});

export const {
	useUserListQuery,
	useUserCreateMutation,
	useUserUpdateCompanyMutation,
	useUserUnAssignGroupMutation,
	useUserDeleteMutation,
	useUserAssignGroupMutation,
} = userApi;
