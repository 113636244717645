import * as z from 'zod';
import { useEffect } from 'react';
import {Form} from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { Modal } from '@/components/modal/modal';
import { InputField } from '@/components/input';
import toast from 'react-hot-toast';
import { useCreateGroupMutation } from '@/app/groups/api';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
});

export type CreateGroupInput = z.TypeOf<typeof schema>;

export const CreateGroup = (props: { open: boolean, onClose: () => void  }) => {
    const [onUpdate, { isLoading, isSuccess, data, isError, error }] = useCreateGroupMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
            if (data) {
                navigate(`edit/${data?.id}`);
            };
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    return (
        <Modal  title='Create Group' onClose={props.onClose} opened={props.open}>
            <Form<CreateGroupInput, typeof schema>
                schema={schema}
                onSubmit={payload => onUpdate(payload)}
            >
                {({ register, formState }) => (
                    <>
                        <InputField
                            type='text'
                            label='Name'
                            placeholder='Eg: admin'
                            loading={isLoading}
                            error={formState.errors.name}
                            registration={register('name')}
                        />

                        <button type='submit' disabled={isLoading} className='btn btn-primary  mt-6 w-full'>
                            <BookmarkIcon className='icon-md w-4 mr-3' /> Create Group
                        </button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
