import { LoginModel, LoginInput } from '@/app/auth';
import {UserInfo, userSlice} from '@/app/user';
import baseApi from '@/core/lib/base';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';
import { SignupInput } from '@/app/auth';
import toast from 'react-hot-toast';

export const authApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		login: builder.mutation<LoginModel, LoginInput>({
			query: payload => ({
				method: 'POST',
				url: 'user/login',
				body: payload,
			}),
            invalidatesTags: [
                'CompaniesAll',
                'CompaniesWithFilter',
                'Users',
                'Apikeys',
                'Customers',
                'Items',
                'Invoices',
                'Roles',
                'Groups',
                'Clients'
            ],
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					const user = {
						id: data.userInfo.id,
						phone: data.userInfo.email,
						firstname: data.userInfo.firstName,
						lastname: data.userInfo.lastName,
						email: data.userInfo.email,
						role: data.userInfo.realmRoles,
						isActive: data.userInfo.enabled,
						created_at: data.userInfo.created_at,
						updated_at: data.userInfo.updated_at,
						company: null,
					}
					storage.setToken(data.accessToken.access_token);
					dispatch(userSlice.actions.setUser(user));
				} catch (error: any) {
                    toast.error(error.error.data.message);
				}
			},
		}),

		signup: builder.mutation<ResponseModel<null>, SignupInput>({
			query: payload => ({
				method: 'POST',
				url: 'user/signup',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Registration request submited');
				} catch (error: any) {
                    toast.error(error.error.data.message);
                }
			},
		}),

		whoami: builder.query<UserInfo, null>({
			query: () => ({
				method: 'GET',
				url: 'user/check',
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;
					const user = {
						id: data.id,
						phone: data.email,
						firstname: data.firstName,
						lastname: data.lastName,
						email: data.email,
						role: data.realmRoles,
						isActive: data.enabled,
						created_at: data.created_at,
						updated_at: data.updated_at,
						company: null,
					}
					dispatch(userSlice.actions.setUser(user));
				} catch (error) {
					storage.removeToken();
					dispatch(userSlice.actions.logout());
				}
			},
		}),
	}),
});

export const { useLoginMutation, useSignupMutation, useWhoamiQuery } = authApi;
