import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useLazyCustomerSearchByTinQuery } from '@/app/customer';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useInvoiceCreateAggregatorMutation } from '@/app/invoice/redux';
import { useCompanyListAllQuery } from '@/app/company';
import { InputField } from '@/components/input';
import { Button, Modal } from '@mantine/core';
import SelectField from '@/components/select/SelectField';

type CustomerType = {
	loading: boolean;
	control: any;
	register: any;
	formState: any;
	setValue: any;
};

const Customer = (props: CustomerType) => {
	const { loading, control, register, formState, setValue } = props;

	const companyId = useWatch({ control, name: 'companyId' });
	const tin = useWatch({ control, name: 'customer.tin' });

	const [onSearch, { isSuccess, data }] = useLazyCustomerSearchByTinQuery();

	useEffect(() => {
		if (tin && tin.length === 6) onSearch({ tin, company: companyId });
		// eslint-disable-next-line
	}, [tin]);

	useEffect(() => {
		setValue('customer.tin', '');
		handleSetValue('', '', false);
		// eslint-disable-next-line
	}, [companyId]);

	useEffect(() => {
		if (data?.data) {
			handleSetValue(data.data.name, data.data.phoneNumber);
		} else {
			handleSetValue('', '', false);
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSetValue = (
		name: string,
		phone: string,
		validate: boolean = true
	) => {
		setValue('customer.name', name, { shouldValidate: validate });
		setValue('customer.phone', phone, { shouldValidate: validate });
	};

	return (
		<div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
			<InputField
				type='text'
				label='Customer Tin'
				placeholder='Eg: 123456'
				loading={loading}
				error={formState.errors.customer?.tin}
				registration={register('customer.tin')}
				className='h-14'
			/>

			<InputField
				type='text'
				label='customer name'
				placeholder='Eg: John Doe'
				loading={loading}
				disabled={!tin || tin.length !== 6 || !!data}
				error={formState.errors.customer?.name}
				registration={register('customer.name')}
				className='h-14'
			/>

			<InputField
				type='text'
				label='Customer phone'
				placeholder='Eg: 2507 XXX XXX'
				loading={loading}
				disabled={!tin || tin.length !== 6 || !!data}
				error={formState.errors.customer?.phone}
				registration={register('customer.phone')}
				className='h-14'
			/>
		</div>
	);
};

type InputWrapperProps = {
	loading: boolean;
	control: any;
	register: any;
	formState: any;
	setValue: any;
};

const InputWrapper = (props: InputWrapperProps) => {
	const { loading, control, register, formState, setValue } = props;

	const companyId = useWatch({ control, name: 'companyId' });

	return !companyId ? null : (
		<>
			<Customer
				loading={loading}
				control={control}
				register={register}
				formState={formState}
				setValue={setValue}
			/>

			<InputField
				type='number'
				label='Item quantity'
				placeholder='Eg: 10'
				loading={loading}
				error={formState.errors.item?.quantity}
				registration={register('item.quantity')}
				className='h-14'
			/>

			<InputField
				type='number'
				label='Item unit price'
				placeholder='Eg: 1000'
				loading={loading}
				error={formState.errors.item?.unitPrice}
				registration={register('item.unitPrice')}
				className='h-14'
			/>
		</>
	);
};

const schema = z.object({
	companyId: z.string().min(1, 'Company required'),
	customer: z.object({
		tin: z
			.string()
			.min(6, 'tin must be 6 characters')
			.max(6, 'tin must be 6 characters'),
		name: z.string().min(1, 'Name required'),
		phone: z.string().optional(),
	}),
	item: z.object({
		quantity: z.string().min(1, 'Quantity required'),
		unitPrice: z.string().min(1, 'Unit price required'),
	}),
});

export type InvoiceAggregatorInput = z.TypeOf<typeof schema>;

export const InvoiceCreateAggregator = () => {
	const navigate = useNavigate();

	const [onCreate, { isLoading, isSuccess }] =
		useInvoiceCreateAggregatorMutation();

	const { data: companies, isFetching } = useCompanyListAllQuery({
		pageNumber: 0,
		pageSize: 1000,
	});

	useEffect(() => {
		if (isSuccess) navigate('/invoice');
	}, [isSuccess, navigate]);

	console.log(companies?.list)

	return (
		<Modal
            opened={false}
			size='xl'
			title='New Invoice'
			// loading={isLoading}
			onClose={() => navigate('/invoice')}
		>
			<Form<InvoiceAggregatorInput, typeof schema>
				schema={schema}
				onSubmit={onCreate}
			>
				{({ control, register, formState, setValue }) => (
					<>
						<div className='space-y-5'>
							<SelectField
								label='Company'
								options={
									companies
										? companies?.list
												.filter(v => v.status === 'active')
												.map(company => {
													return { value: company.id, label : company.name };
												})
										: []
								}
								// loading={isFetching}
								error={formState.errors.companyId}
								registration={register('companyId')}
								className='h-14'
							/>

							<InputWrapper
								loading={isLoading}
								control={control}
								register={register}
								formState={formState}
								setValue={setValue}
							/>
						</div>

						<Button type='submit' loading={isLoading} className='mt-6 w-full'>
							<BookmarkIcon className='icon-md' /> Create
						</Button>
					</>
				)}
			</Form>
		</Modal>
	);
};
