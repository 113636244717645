import { Link, Outlet } from 'react-router-dom';
import { PencilIcon, PlusIcon, TrashIcon, UserMinusIcon } from '@heroicons/react/24/outline';
import { usePaginate } from '@/core/hooks';
import {UserPlusIcon} from "@heroicons/react/20/solid";
import {GroupModel} from "@/app/groups/types";
import {useGroupsListQuery} from "@/app/groups/api";
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { Header } from '@mantine/core';
import { useAppSelector } from '@/store';
import { useState } from 'react';
import { GroupAssignRole } from '@/app/groups/components/GroupAssignRoles';
import { CreateGroup } from '@/app/groups/components/GroupCreate';
import { GroupUnAssignRole } from './GroupUnAssignRoles';

export const GroupList = () => {
	const { user } = useAppSelector(state => state.userState);
	const paginate = usePaginate();

    const [open , setOpen] = useState(false);
    const [openU , setOpenU] = useState(false);
    const [selected, setSelected] = useState<GroupModel | null>(null);

	const { isLoading, data } = useGroupsListQuery({
		...paginate.paginate,
	});


	const columns: TableColumn<GroupModel>[] = [
		{
			accessor: 'name',
			title: 'Group Name',
			render: row => row.name,
		},
		{
            accessor:' ',
			title: '',
			render: row =>
				row.id !== user?.id && (
					<div className='flex justify-end gap-2'>
                        {user?.role.includes('unassign_group_roles') && (
                            <Link to={`edit/${row.id}`}>
                                <button onClick={() => {
                                    setSelected(row);
                                    setOpenU(true);
                                }} title={'Edit Group'} className="btn btn-info btn-xs">
                                    <PencilIcon className="icon-sm w-4 mr-3" /> Edit
                                </button>
                            </Link>
                        )}

                        {user?.role.includes('delete_groups') && (
                            <Link to={`edit/${row.id}`}>
                                <button onClick={() => {
                                    setSelected(row);
                                    setOpenU(true);
                                }} title={'Edit Group'} className="btn btn-danger btn-xs">
                                    <TrashIcon className="icon-sm w-4 mr-3" /> Delete
                                </button>
                            </Link>
                        )}
                    </div>
                ),
        },
    ];

    const [openCreate, setOpenCreate] = useState(false);

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Group</span>
                </li>
            </ul>
            <CreateGroup open={openCreate} onClose={() => setOpenCreate(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Groups</h1>
                {user?.role.includes('create_groups') && <div onClick={() => setOpenCreate(true)}
                                                              className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New Group</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Users"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<div />}
            />

            <Outlet />
        </>
    );
};
