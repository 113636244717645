

import baseApi from "@/core/lib/base";

interface InvoiceData {
    total: number;
    approvedInvoice: number;
    canceledInvoice: number;
}

export interface StatsModel {
    invoice: InvoiceData;
    customer: number;
    company: number;
    users: number;
}


export interface WeekTransactionModel {
    total: number,
    day: string,
    date: string
}

export interface MonthTransactionModel {
    total: number,
    month: string,
    year: number
}

export interface YearTransactionModel {
    total: number,
    year: number
}

export const statsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStats: builder.query<StatsModel, {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/report/stats",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        getWeek: builder.query<WeekTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/report/week",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        getMonth: builder.query<MonthTransactionModel[], { year: string }>({
            query: (params) => {
                return {
                    method: "GET",
                    url: `/report/month?year=${params.year}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        getYear: builder.query<YearTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/report/year",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useGetStatsQuery,
    useGetMonthQuery,
    useGetWeekQuery,
    useGetYearQuery,
} = statsApi;
