import { CustomerModel, CustomerInput } from '@/app/customer';
import { Paginate } from '@/core/hooks';
import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';

export const customerApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		customerList: builder.query<ResponseListModel<CustomerModel>, Paginate>({
			query: paginate => ({
				method: 'GET',
				url: 'customer/',
				params: paginate,
			}),
            providesTags: ['Customers'],
		}),

		customerSearchByTin: builder.query<
			ResponseModel<CustomerModel>,
			{ tin: string; company: string }
		>({
			query: filter => ({
				method: 'GET',
				url: 'customer/search-tin',
				params: filter,
			}),
		}),

		customerCreate: builder.mutation<
			ResponseModel<CustomerModel>,
			CustomerInput
		>({
			query: payload => ({
				method: 'POST',
				url: 'customer/',
				body: payload,
			}),
			onQueryStarted: async (_, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Customer created');
				} catch (error) {}
			},
            invalidatesTags: ['Customers']
		}),

		customerDelete: builder.mutation<ResponseModel<null>, { id: string }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `customer/${id}`,
			}),
			onQueryStarted: async (_, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Customer deleted');
				} catch (error) {}
			},
            invalidatesTags: ['Customers']
		}),
	}),
});

export const {
	useCustomerListQuery,
	useLazyCustomerSearchByTinQuery,
	useCustomerCreateMutation,
	useCustomerDeleteMutation,
} = customerApi;
