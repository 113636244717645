import { ItemModel, ItemInput } from '@/app/item';
import { Paginate } from '@/core/hooks';
import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import toast from 'react-hot-toast';

export const itemApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		itemList: builder.query<ResponseListModel<ItemModel>, Paginate>({
			query: paginate => ({
				method: 'GET',
				url: 'item/',
				params: paginate,
			}),
            providesTags: ['Items'],
		}),

		itemCreate: builder.mutation<ResponseModel<ItemModel>, ItemInput>({
			query: payload => ({
				method: 'POST',
				url: 'item/',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Item created');
				} catch (error) {}
			},
            invalidatesTags: ['Items'],
		}),
	}),
});

export const { useItemListQuery, useItemCreateMutation } = itemApi;
