import { useItemListQuery } from '@/app/item/api';
import { ItemModel } from '@/app/item/types';
import { usePaginate } from '@/core/hooks';
import { format } from '@/utils';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link, Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { Header } from '@mantine/core';
import { ItemCreate } from '@/app/item';

export const ItemList = () => {
	const { user } = useAppSelector(state => state.userState);

	const paginate = usePaginate();

	const { isLoading, data } = useItemListQuery({
		...paginate.paginate,
	});

	const columns: TableColumn<ItemModel>[] = [
		{
			accessor: 'code',
			title: 'Code',
			render: row => row.code,
		},
		{
			accessor: 'name',
			title: 'Name',
			render: row => row.name,
		},
		{
			accessor: 'item_type.name',
			title: 'Item type',
			render: row => row.item_type.name,
		},
		{
			accessor: 'country.name',
			title: 'Country',
			render: row => row.country.name,
		},
		{
			accessor: 'packaging_unit.name',
			title: 'Package unit',
			render: row => row.packaging_unit.name,
		},
		{
			accessor: 'quantity_unit.name',
			title: 'Quantity unit',
			render: row => row.quantity_unit.name,
		},
		{
			accessor: 'tax_type.id',
			title: 'Tax type',
			render: row => row.tax_type.name,
		},
		{
			accessor: 'unitPrice',
			title: 'Unit price',
			render: row => format.currency(row.unitPrice),
		},
		{
			accessor: 'insurance',
			title: 'Insurance',
			render: row => (row.insurance ? 'Yes' : 'No'),
		},
		{
			accessor: 'isItemUsed',
			title: 'Is used',
			render: row => (row.isItemUsed ? 'Yes' : 'No'),
		},
		{
			accessor: 'isDefault',
			title: 'Default',
			render: row => (row.isDefault ? 'Yes' : 'No'),
		},
	];

    const [open , setOpen] = useState(false);

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Items</span>
                </li>
            </ul>
            <ItemCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Items</h1>
                {['create_item'].some(value => user?.role.includes(value)) && (
                    <div onClick={() => setOpen(true)}
                         className="btn btn-primary btn-sm">
                        <PlusIcon className="icon-sm w-6" />
                        <p className={'flex flex-row gap-1'}>New Item</p>
                    </div>
                )}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Items"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<></>} />

            <Outlet />
        </>
    );
};
