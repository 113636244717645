import baseApi from '@/core/lib/base';
import {ResponseListModel, ResponseModel} from '@/core/types';
import {RoleModel} from "@/app/roles/types";
import {UserModel} from "@/app/user";
import {GroupAssignRoleInput} from "@/app/groups/components/GroupAssignRoles";
import toast from "react-hot-toast";
import {CreateRoleInput} from "@/app/roles/components/RoleCreate";

export const rolesApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        rolesList: builder.query<
            ResponseListModel<RoleModel>,{} >({
            query: () => ({
                method: 'GET',
                url: 'role',
                params: {
                    pageNumber: 0,
                    pageSize: 1500,
                },
            }),
            providesTags: ['Roles'],
        }),

        createRole: builder.mutation<ResponseModel<UserModel>, CreateRoleInput>({
            query: payload => ({
                method: 'POST',
                url: '/role',
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    toast.success('Role Created');
                } catch (error) {}
            },
            invalidatesTags: ['Roles'],
        }),
    }),
});

export const { useRolesListQuery, useCreateRoleMutation } = rolesApi;
