import * as z from 'zod';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useUserUpdateCompanyMutation } from '@/app/user/redux';
import { useCompanyListAllQuery } from '@/app/company';
import { Button, Modal } from '@mantine/core';
import SelectField from '@/components/select/SelectField';

const schema = z.object({
	companyId: z.string().min(1, 'Company required'),
});

export type UserUpdateCompanyInput = z.TypeOf<typeof schema>;

export const UserUpdateCompany = (props: { open: boolean, onClose: () => void, userId: string }) => {
	const navigate = useNavigate();

	const [onUpdate, { isLoading, isSuccess }] = useUserUpdateCompanyMutation();

	const { isLoading: companyIsLoading, data: companies } =
		useCompanyListAllQuery({
			pageNumber: 0,
			pageSize: 1000,
		});

	useEffect(() => {
		if (isSuccess) navigate(0);
	}, [isSuccess, navigate]);

	return (
		<Modal opened={props.open} title='Update User Company' onClose={props.onClose}>
			<Form<UserUpdateCompanyInput, typeof schema>
				schema={schema}
				onSubmit={payload => onUpdate({ id: props.userId ?? '', payload })}
			>
				{({ register, formState, }) => (
					<>
						<SelectField
							label='Company'
							options={
								companies
									? companies.list.map(company => {
											return { value: company.id, label : company.name };
									  })
									: []
							}
							// loading={isLoading || companyIsLoading}
							error={formState.errors.companyId}
							registration={register('companyId')}
							className='h-14'
						/>

						<button type='submit' disabled={isLoading} className='btn btn-primary mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4' /> Update
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
