import { Link, Outlet, useLocation } from 'react-router-dom';
import { PlusIcon, UsersIcon } from '@heroicons/react/24/outline';

import {
    UserCommonModel, UserCreate, UserUpdateCompany,
    useUserDeleteMutation,
    useUserListQuery,
} from '@/app/user';
import { format } from '@/utils';
import {
	TrashIcon,
} from '@heroicons/react/24/outline';
import { usePaginate } from '@/core/hooks';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { EyeIcon } from '@heroicons/react/16/solid';
import ViewUser from '@/app/user/components/ViewUser';
import Confirmation from '@/components/confirmation';
import { UserAssignCompany } from '@/app/user/components/UserAssignGroup';

export const UserList = () => {
	const { user } = useAppSelector(state => state.userState);

    const location = useLocation();

    const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setQueryParams(params);
    }, [location.search]);

	const { isFetching, data } = useUserListQuery(queryParams);
	const [onDelete, { isLoading: isLoadingDelete }] = useUserDeleteMutation();

    const [open , setOpen] = useState(false);
    const [openU , setOpenU] = useState(false);


    const  [openView, setOpenView] = useState(false);
    const  [openUpdate, setOpenUpdate] = useState(false);

    const [userSelected, setUserSelected] = useState<UserCommonModel | null>(null);


    const columns: TableColumn<UserCommonModel>[] = [
		{
			accessor: 'userName',
			title: 'User Name',
			render: row => row.username,
		},
		{
			accessor: 'email',
			title: 'Email address',
			render: row => row.email,
		},
		{
			accessor: 'groups',
			title: 'Groups',
			render: row => {
				return <div className={'text-sm max-w-[300px] overflow-x-scroll scrollbar-hidden flex flex-row'}>
					{row.groups.map((item, index) => <div className={'px-[5px] py-[3px] text-[13px] rounded-md mr-1 bg-primary text-white'} key={index}>{item.name}</div>)}
				</div>
			},
		},
		{
            accessor: 'enabled',
			title: 'Status',
			render: row => (row.enabled ? 'Yes' : 'No'),
		},
		{
            accessor: 'Created_at',
			title: 'Created',
			render: row => format.dateTime(row.created_at),
		},
		{
            accessor: ' ',
			title: '',
			render: row =>
				row.id !== user?.id && (
					<div className='flex justify-end gap-2'>
						{/*{!row.roles.some(role => role.name === 'update_company') && (*/}
                        {/*    <button onClick={() => {*/}
                        {/*        setUserSelected(row);*/}
                        {/*        setOpenUpdate(true);*/}
                        {/*    }} title={'Update Company'} className="btn text-white bg-blue-500 btn-xs">*/}
                        {/*        <UserGroupIcon className="icon-sm w-5" />*/}
                        {/*    </button>*/}
                        {/*)}*/}

                        {user?.role.includes('find_users') && (
                            <button onClick={() => {
                                setUserSelected(row);
                                setOpenView(true);
                            }} title={'View User'} className="btn text-white bg-gray-600 btn-sm">
                            <EyeIcon className='icon-sm w-5' />
							</button>
						)}

                        {user?.role.includes('assign_users_to_group') && (
                            <button onClick={() => {
                                setUserSelected(row);
                                setOpenU(true);
                            }} title={'Assign Groups'} className="btn text-white bg-blue-600 btn-sm">
                                <UsersIcon className='icon-sm w-5' />
                            </button>
                        )}


						<Confirmation
                            title={'Delete Account'}
							loading={isLoadingDelete}
							onConfirm={() => onDelete({ id: row.id })}
						>
							{({ onOpen }) => (
								<button title={'Delete User'} className={'btn btn-danger btn-sm'} onClick={onOpen}>
									<TrashIcon className='icon-sm w-5' />
								</button>
							)}
						</Confirmation>
					</div>
				),
		},
	];

	return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Users</span>
                </li>
            </ul>
            <UserUpdateCompany userId={userSelected?.id ?? ''} open={openUpdate} onClose={() => setOpenUpdate(false)} />
            <UserCreate open={open} onClose={() => setOpen(false)} />
            <ViewUser user={userSelected} open={openView} onClose={() => setOpenView(false)} />
            <UserAssignCompany open={openU} onClose={() => setOpenU(false)} user={userSelected} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Users</h1>
                {user?.role.includes('create_users') && <div onClick={() => setOpen(true)}
                                                             className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New User</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list.filter((itm) => !(itm.email == null && itm.firstName == null)) ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Users"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
            />

            <Outlet />
        </>
    );
};
