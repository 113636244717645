import * as z from 'zod';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { BookmarkIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { useUserAssignGroupMutation, useUserUnAssignGroupMutation } from '@/app/user/redux';
import {useGroupsListQuery} from "@/app/groups/api";
import { Modal } from '@/components/modal/modal';
import SelectField from '@/components/select/SelectField';
import toast, { LoaderIcon } from 'react-hot-toast';
import { UserCommonModel, UserModel } from '@/app/user';
import { GroupModel } from '@/app/groups/types';
import { Tab } from '@headlessui/react';
import { FiUserX } from 'react-icons/fi';
import { GroupAssignRole } from '@/app/groups/components/GroupAssignRoles';
import { GroupUnAssignRole } from '@/app/groups/components/GroupUnAssignRoles';
import { UpdateGroup } from '@/app/groups/components/updateGroup';

const schema = z.object({
    groupId: z.string().min(1, { message: 'Group is required' }),
});

export type UserAssignGroupInput = z.TypeOf<typeof schema>;

export const UserAssignCompany = (props: { open: boolean, onClose: () => void, user: UserCommonModel | null }) => {

    const [onUpdate, { isLoading, isSuccess, isError, error }] = useUserAssignGroupMutation();
    const [onUpdateU, { isLoading: isLoadingU, isSuccess: isSuccessU, isError: isErrorU, error: errorU }] = useUserUnAssignGroupMutation();

    const [checkboxValues, setCheckboxValues] = useState<string | null>(null);
    const [checkboxValuesTwo, setCheckboxValuesTwo] = useState<string | null>(null);

    const { isLoading: groupsIsLoading, data: groups } =
        useGroupsListQuery({
            pageNumber: 0,
            pageSize: 1000,
        });

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
            setCheckboxValues(null);
            setCheckboxValuesTwo(null);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
            setCheckboxValues(null);
            setCheckboxValuesTwo(null);
        }
    }, [isSuccessU]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    const handleSubmit = () => {
        const validationResult = schema.safeParse({ groupId: checkboxValues });
        if (validationResult.success) {
            onUpdate({ id: props.user?.id ?? '', data: validationResult.data });
        } else {
            console.log(validationResult.error.errors);
            toast.error('Please Select a Group');
        }
    };

    const handleSubmitU = () => {
        const validationResult = schema.safeParse({ groupId: checkboxValuesTwo });
        if (validationResult.success) {
            onUpdateU({ id: props.user?.id ?? '', data: validationResult.data });
        } else {
            console.log(validationResult.error.errors);
            toast.error('Please Select a Group');
        }
    };

    const tabs = [
        {
            icon: UserPlusIcon,
            title: 'Assign Group',
            selected: true,
        },
        {
            icon: UserMinusIcon,
            title: 'Un-Assign Group',
            selected: true,
        },
    ];

    const handleCheckboxChange = (id: string) => {
        setCheckboxValues(id);
    };

    const handleCheckboxChangeTwo = (id: string) => {
        setCheckboxValuesTwo(id);
    };

    return (
        <Modal opened={props.open} title={`Assign ${props.user?.username} to Group`} onClose={() => {
            props.onClose();
            setCheckboxValues(null);
            setCheckboxValuesTwo(null);
        }}>
            <div className="w-full bg-white dark:bg-slate-800 rounded-md">
                <Tab.Group>
                    <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                        {tabs.map((item, index) => (
                            <Tab key={index} as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={`${
                                            selected
                                                ? '!border-white-light hover:text-gray-100 !border-b-white text-primary !outline-none dark:text-white dark:!border-[#39437b] dark:!border-b-black'
                                                : 'dark:text-gray-400'
                                        }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-gray-300`}
                                    >
                                        <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                        {item.title}
                                    </button>
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel>
                            <div className="p-4 w-full flex gap-3 flex-wrap">
                                { !groupsIsLoading ? groups?.list.map((group, index) =>
                                    <div key={index}>
                                        <label className="flex w-32 border p-2 rounded-md border-gray-100 flex-row justify-between">
                                        <span className="flex flex-col gap-1">
                                            <span className={'text-sm'}>{group.name}</span>
                                        </span>
                                            <span className="flex flex-row gap-1">
                                                <span className="w-5">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox text-success"
                                                        checked={group.id === checkboxValues}
                                                        // defaultChecked={props.user?.groups.some((itm) => itm.id === group.id)}
                                                        disabled={props.user?.groups.some((itm) => itm.id === group.id)}
                                                        onClick={() => handleCheckboxChange(group.id)}
                                                    />
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                ) : <LoaderIcon className={'animate-spin'} />}
                            </div>
                            <div onClick={() => handleSubmit()} className={'btn py-[0.7rem] w-20 btn-sm btn-primary'}>
                                {isLoading ? <div><LoaderIcon /></div> : 'Save' }
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="p-4 w-full flex gap-3 flex-wrap">
                                { !groupsIsLoading ? groups?.list.map((group, index) =>
                                    <div key={index}>
                                        <label className="flex w-32 border p-2 rounded-md border-gray-100 flex-row justify-between">
                                        <span className="flex flex-col gap-1">
                                            <span className={'text-sm'}>{group.name}</span>
                                        </span>
                                            <span className="flex flex-row gap-1">
                                                <span className="w-5">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox text-success"
                                                        checked={group.id === checkboxValuesTwo}
                                                        // defaultChecked={props.user?.groups.some((itm) => itm.id === group.id)}
                                                        disabled={!props.user?.groups.some((itm) => itm.id === group.id)}
                                                        onClick={() => handleCheckboxChangeTwo(group.id)}
                                                    />
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                ) : <LoaderIcon className={'animate-spin'} />}
                            </div>
                            <div onClick={() => handleSubmitU()} className={'btn py-[0.7rem] w-20 btn-sm btn-primary'}>
                                {isLoadingU ? <div><LoaderIcon /></div> : 'Save' }
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};
