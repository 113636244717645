import { Outlet, ScrollRestoration } from 'react-router-dom';

import Logo from '@/assets/images/logo.png';
import StockBanner from '@/assets/images/stock-banner.png';

export const AuthLayout = () => {
	return (
		<>
            <div className="flex p-5 lg:p-10 min-h-screen">
                <div className='grid w-full grid-cols-1 lg:grid-cols-2 bg-white overflow-clip shadow-2xl rounded-lg'>
                    <div className='hidden bg-light lg:flex lg:items-center lg:justify-center'>
                        <img alt='stock-banner' src={StockBanner} className='w-[80%]' />
                    </div>

                    <main className='bg-white w-full'>
                        <div className='container flex p-5 flex-col justify-between gap-12 py-14 lg:px-28'>
                            <div>
                                <img alt='FDI-EBM' src={Logo} />
                            </div>

                            <div>
                                <Outlet />
                            </div>

                            <div></div>
                        </div>
                    </main>
                </div>
            </div>

            <ScrollRestoration />
        </>
    );
};
