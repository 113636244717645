import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CompanyModel } from '@/app/company/types';
import {
    useCompanyCreateMutation,
    useCompanyDeleteMutation,
    useCompanyListWithFilterQuery,
    useCompanyUpdateStatusMutation, useCompanyVerifyMutation,
} from '@/app/company/redux';
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import { usePaginate } from '@/core/hooks';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { Header } from '@mantine/core';
import React, { useState } from 'react';
import Confirmation from '@/components/confirmation';
import { CompanyCreate } from '@/app/company';
import { useAppSelector } from '@/store';
import { FaCircleXmark } from 'react-icons/fa6';

export const CompanyList = () => {
	const paginate = usePaginate();

    const { user } = useAppSelector((store) => store.userState);

	const { isFetching, data } = useCompanyListWithFilterQuery({
		...paginate.paginate,
	});
	const [onUpdateStatus, { isLoading: isLoadingUpdateStatus }] =
		useCompanyUpdateStatusMutation();
	const [onDelete, { isLoading: isLoadingDelete }] = useCompanyDeleteMutation();
    const [onApprove, { isLoading: loadApprove }] = useCompanyVerifyMutation();


    const columns: TableColumn<CompanyModel>[] = [
		{
			accessor: 'name',
			title: 'Company name',
			render: row => row.name,
		},
		{
			accessor: 'tin',
			title: 'Tin',
			render: row => row.tin,
		},
		{
			accessor: 'branchId',
			title: 'Branch Id',
			render: row => row.branchId,
		},
		{
			accessor: 'status',
			title: 'Verified',
			render: row => (row.status === 'active' ? 'Yes' : 'No'),
		},
		{
            accessor: 'created_at',
			title: 'Created',
			render: row => format.dateTime(row.created_at),
		},
		{
            accessor: ' ',
			title: '',
			render: row => (
				<div className='flex justify-end gap-2'>
					<Confirmation
                        title={'Delete Company'}
						loading={isLoadingDelete}
						onConfirm={() => onDelete({ id: row.id })}
					>
						{({ onOpen }) => (
							<button title={'Delete'} className={'btn btn-danger btn-sm'} onClick={onOpen}>
								<TrashIcon className='icon-sm w-4' />
							</button>
						)}
					</Confirmation>
                    <Confirmation
                        title={ row.status === 'active' ? 'Cancel Company' :  'Verify Company'}
                        loading={isLoadingDelete}
                        onConfirm={() => onApprove({ id: row.id })}
                    >
                        {({ onOpen }) => (
                            <button title={'Update Status'} className={`btn ${row.status === 'active' ? 'btn-danger' : 'btn-primary' } btn-sm`} onClick={onOpen}>
                                { row.status === 'active' ? <FaCircleXmark className="icon-sm w-4" />  : <CheckCircleIcon className="icon-sm w-4" />}
                            </button>
                        )}
                    </Confirmation>
				</div>
			),
		},
	];

    const [open , setOpen] = useState(false);


    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Companies</span>
                </li>
            </ul>
            <CompanyCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title="Companies">
                <h1 className={'p-4 text-lg'}>Companies</h1>
                {user?.role.includes('create_company') &&
                    <button onClick={() => setOpen(true)} className="btn btn-sm btn-primary">
                        <PlusIcon className="icon-sm w-5" />
                        New Company
                    </button>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Companies"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isFetching}
                filterComponent={<div />}
            />

            <Outlet />
        </>
    );
};
