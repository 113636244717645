import * as z from 'zod';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import {useGroupAssignRoleMutation,} from "@/app/groups/api";
import {useRolesListQuery} from "@/app/roles/api";
import { Modal } from '@/components/modal/modal';
import toast from 'react-hot-toast';
import { GroupModel } from '@/app/groups/types';

const schema = z.object({
    roleIds: z.array(z.string()).min(1, { message: 'At least one role is required' }),
});

export type GroupAssignRoleInput = z.TypeOf<typeof schema>;

export const GroupAssignRole = (props: { group: GroupModel | null }) => {
    const navigate = useNavigate();

    const [onUpdate, { isLoading, isSuccess, isError, error }] = useGroupAssignRoleMutation();

    const { isLoading: rolesIsLoading, data: roles } =
        useRolesListQuery({
            pageNumber: 0,
            pageSize: 1000,
        });

    useEffect(() => {
        if (isSuccess) {
          setCheckboxValues([]);
        }
    }, [isSuccess, navigate]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    const [checkboxValues, setCheckboxValues] = useState<string[]>([]);

    const handleSubmit = () => {
        const validationResult = schema.safeParse({ roleIds: checkboxValues });
        if (validationResult.success) {
            onUpdate({  data: validationResult.data, id: props.group?.id ?? '' });
        } else {
            console.log(validationResult.error.errors);
            toast.error('Please Select New Role');
        }
    };

    const handleCheckboxChange = (id: string) => {
        setCheckboxValues(prevValues => {
            if (prevValues.includes(id)) {
                return prevValues.filter(item => item !== id);
            } else {
                return [...prevValues, id];
            }
        });
    };

    const usersR = [ 'view_users', 'create_users', 'unassign_users_to_group', 'find_users', 'assign_users_to_group' ];
    const groupsR = ['assign_group_roles', 'unassign_group_roles', 'create_groups','view_groups', 'find_groups'];
    const invoiceR = ['create_invoice', 'find_invoice', 'view_invoice',];
    const companyR = ['update_company', 'create_company', 'find_company','view_company', 'delete_company'];
    const itemR = ['find_item', 'create_item', 'delete_item', 'update_item' ];
    const customerR = ['find_customer', 'create_customer','view_customer', 'delete_customer', 'update_customer'];
    const other = ['offline_access', 'view_report']

    return (
        <div>
            <div className={'m-4'}>Assign Roles</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                <div className="flex flex-col items-start justify-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>User access</p>
                    {!rolesIsLoading && roles?.list.filter(item => usersR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="flex flex-row gap-1">
                                    <span className="w-5">
                                    <input
                                        type="checkbox"
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Group access</p>
                    {!rolesIsLoading && roles?.list .filter(item => groupsR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Invoice access</p>
                    {!rolesIsLoading && roles?.list .filter(item => invoiceR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Company access</p>
                    {!rolesIsLoading && roles?.list .filter(item => companyR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Item access</p>
                    {!rolesIsLoading && roles?.list .filter(item => itemR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Customer access</p>
                    {!rolesIsLoading && roles?.list .filter(item => customerR.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col justify-start items-start gap-2 border dark:border-gray-500/50 shadow-sm p-2 rounded-md text-xs">
                    <p>Other access</p>
                    {!rolesIsLoading && roles?.list .filter(item => other.includes(item.name)).map((item, index) => (
                        <div key={index} className={'w-full'}>
                            <label className="flex flex-row justify-between">
                                <span className="flex flex-col gap-1">
                                    <span className={'text-sm'}>{item.name}</span>
                                    <span className={'text-gray-500'}>{item.description}</span>
                                </span>
                                <span className="w-5">
                                    <input
                                        type="checkbox"
                                        disabled={props.group?.roles.some((itm) => itm.id === item.id)}
                                        onClick={() => handleCheckboxChange(item.id)}
                                        className="form-checkbox text-success"
                                        defaultChecked={props.group?.roles.some((itm) => itm.id === item.id)}
                                    />
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="">
                <button onClick={handleSubmit} type="submit" disabled={isLoading} className=" btn btn-primary mt-6">
                    <BookmarkIcon className="icon-md w-4 mr-3" /> Save
                </button>
            </div>
        </div>
    );
};
