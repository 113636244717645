import * as z from 'zod';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useCompanyCreateMutation } from '@/app/company';
import { InputField } from '@/components/input';
import { Form } from '@/components/form';
import toast from 'react-hot-toast';
import { Modal } from '@/components/modal/modal';

const schema = z.object({
	name: z.string().min(1, 'Company name required'),
	tin: z.string().min(1, 'Tin required'),
	branchId: z.string().min(1, 'Branch id required'),
    deviceSerialNumber: z.string().min(1, 'Device Serial ID is required'),
});

export type CompanyInput = z.TypeOf<typeof schema>;

export const CompanyCreate = (props: { open: boolean, onClose: () => void }) => {
	const navigate = useNavigate();

	const [onCreate, { isLoading, isSuccess, error, isError }] = useCompanyCreateMutation();

	useEffect(() => {
		if (isSuccess) {
            navigate('/company');
            props.onClose();
        };
	}, [isSuccess, navigate]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

	return (
		<Modal
            opened={props.open}
			title='New Company'
			// loading={isLoading}
			onClose={props.onClose}
		>
			<Form<CompanyInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ register, formState }) => (
					<>
						<div className='space-y-4'>
							<InputField
								type='text'
								label='Company Name'
								placeholder='Eg: Company Ltd'
								loading={isLoading}
								error={formState.errors.name}
								registration={register('name')}

							/>

							<InputField
								type='number'
								label='Tin'
								placeholder='Eg: 123456'
								loading={isLoading}
								error={formState.errors.tin}
								registration={register('tin')}

							/>

							<InputField
								type='number'
								label='Branch ID'
								placeholder='Eg: 00'
								loading={isLoading}
								error={formState.errors.branchId}
								registration={register('branchId')}

							/>
                            <InputField
                                type='text'
                                label='Device Serial Number'
                                placeholder='Eg: 123456'
                                loading={isLoading}
                                error={formState.errors.deviceSerialNumber}
                                registration={register('deviceSerialNumber')}

                            />
						</div>

						<button type='submit' disabled={isLoading} className='btn btn-primary mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4 mr-3' /> Create
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
