import { createBrowserRouter, Navigate } from 'react-router-dom';
import Error404 from '@/errors/4004Error';
import InvoiceReadGlobal from '@/app/invoice/components/invoiceReadGlobal';
import { Login, Logout, Signup } from '@/app/auth';
import { InvoiceList, InvoiceRead } from '@/app/invoice';
import { ItemList } from '@/app/item';
import { AuthGuard, PublicGuard } from '@/core/protector';
import { GroupList } from '@/app/groups/components/GroupList';
import { RolesList } from '@/app/roles/components/RolesList';
import { CompanyList } from '@/app/company';
import { UserList } from '@/app/user';
import { CustomerList } from '@/app/customer';
import { ApikeyCreate, ApikeyList } from '@/app/apikey';
import { Documentation } from '@/app/documentation';
import { AuthLayout } from '@/layouts';
import AppLayout from '@/layouts/AppLayout';
import Error500 from '@/errors/500Error';
import AdminReportsSummary from '@/app/profile';
import { MainDashboard } from '@/app/dashboard';
import { ClientsList } from '@/app/clients/components/ClientsList';
import { EditGroup } from '@/app/groups/components/EditGroup';

export const AppRoutes = createBrowserRouter([
    { path: '/', element: <Navigate to='/login' replace /> },
    {
        errorElement: <Error500 />,
        children: [
            { path: '/find-invoice/:id', element: <InvoiceReadGlobal /> },
            {
                element: <PublicGuard element={<AuthLayout />} />,
                children: [
                    { path: 'login', element: <Login /> },
                    { path: 'signup', element: <Signup /> },
                ],
            },
            {
                element: <AuthGuard element={<AppLayout />} />,
                children: [
                    {
                        path: 'dashboard',
                        element: <MainDashboard />,
                    },
                    {
                        path: 'profile',
                        element: <AdminReportsSummary />
                    },
                    {
                        path: 'invoice',
                        element: <AuthGuard element={<InvoiceList />} roles={['find_invoice']} />,
                    },
                    { path: 'invoice/:invoiceId', element:  <InvoiceRead /> },
                    {
                        path: 'item',
                        element: <AuthGuard element={ <ItemList />} roles={['find_item']} />,
                    },
                    {
                        path: 'client',
                        element: <AuthGuard element={ <ClientsList />} roles={['view_client_credentials']} />,
                    },
                    {
                        path: 'groups',
                        children: [
                            {
                                index: true,
                                element: <AuthGuard element={ <GroupList />} roles={['find_groups']} />

                            },
                            {
                                path: 'edit/:id',
                                element: <EditGroup />
                            },
                        ]
                    },
                    {
                        path: 'roles',
                        element: <AuthGuard element={ <RolesList />} roles={['find_roles']} />
                    },
                    {
                        path: 'company',
                        element: (
                            <AuthGuard
                                roles={['find_company']}
                                element={<CompanyList />}
                            />
                        ),
                    },
                    {
                        path: 'user',
                        element: (
                            <AuthGuard
                                roles={['admin', 'create_users']}
                                element={<UserList />}
                            />
                        ),
                    },
                    {
                        path: 'customer',
                        element: <AuthGuard roles={['find_customer']} element={<CustomerList />} />,
                    },
                    {
                        path: 'apikey',
                        element: (
                            <AuthGuard roles={['find_apikey']} element={<ApikeyList />} />
                        ),
                    },
                    {
                        path: 'documentation',
                        element: (
                            <AuthGuard roles={['admin']} element={<Documentation />} />
                        ),
                    },
                    { path: 'logout', element: <Logout /> },
                ],
            },
        ],
    },
    { path: '*', element: <Error404 /> },
])
