import * as z from 'zod';
import { useEffect } from 'react';
import {Form} from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { Modal } from '@/components/modal/modal';
import { InputField } from '@/components/input';
import toast from 'react-hot-toast';
import { useUpdateGroupMutation } from '@/app/groups/api';
import { GroupModel } from '@/app/groups/types';

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
});

export type CreateGroupInput = z.TypeOf<typeof schema>;

export const UpdateGroup = (props: { group: GroupModel | null }) => {
    const [onUpdate, { isLoading, isSuccess, isError, error }] = useUpdateGroupMutation();

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    return (
        <div>
            <div className={'m-4'}>Update Groups</div>
            <Form<CreateGroupInput, typeof schema>
                schema={schema}
                onSubmit={payload => onUpdate({ id: props.group?.id ?? '', data: payload  })}
            >
                {({ register, formState }) => (
                    <>
                        <InputField
                            type='text'
                            label='Name'
                            placeholder='Eg: admin'
                            loading={isLoading}
                            defaultValue={props.group?.name}
                            error={formState.errors.name}
                            registration={register('name')}
                        />

                        <button type='submit' disabled={isLoading} className='btn btn-primary  mt-6 w-full'>
                            <BookmarkIcon className='icon-md w-4 mr-3' /> Update Group
                        </button>
                    </>
                )}
            </Form>
        </div>
    );
};
