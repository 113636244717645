import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import { usePaginate } from '@/core/hooks';
import { CustomerModel } from '@/app/customer/types';
import {
    CustomerCreate,
    useCustomerDeleteMutation,
    useCustomerListQuery,
} from '@/app/customer';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import React, { useEffect, useState } from 'react';
import Confirmation from '@/components/confirmation';
import { useAppSelector } from '@/store';

export const CustomerList = () => {
	const paginate = usePaginate();

    const { user } = useAppSelector((store) => store.userState);

	const { isLoading, data } = useCustomerListQuery({ ...paginate.paginate });
	const [onDelete, { isLoading: isLoadingDelete, isSuccess }] =
		useCustomerDeleteMutation();

	const columns: TableColumn<CustomerModel>[] = [
		{
			accessor: 'tin',
			title: 'Tin',
			render: row => row.tin,
		},
		{
			accessor: 'name',
			title: 'Name',
			render: row => row.name,
		},
		{
			accessor: 'phoneNumber',
			title: 'Phone',
			render: row => row.phoneNumber,
		},
		{
            accessor: 'created_at',
			title: 'Created',
			render: row => format.dateTime(row.created_at),
		},
        {
            accessor: 'status',
            title: 'Status',
            render: row => <div className={'capitalize'}>{row.status}</div>,
        },
		{
            accessor: ' ',
			title: '',
			render: row => (
				<div className='flex justify-end gap-2'>
					<Confirmation
                        title={'Delete Customer'}
						loading={isLoadingDelete}
						onConfirm={() => onDelete({ id: row.id })}
					>
						{({ onOpen }) => (
							<button title={'Delete'} className={'btn btn-sm btn-danger'} onClick={onOpen}>
								<TrashIcon className='icon-sm w-4' />
							</button>
						)}
					</Confirmation>
				</div>
			),
		},
	];

    const [open , setOpen] = useState(false);

    useEffect(() => {
        if (isSuccess) {

        }
    }, [isSuccess]);

    return (
        <>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Customers</span>
                </li>
            </ul>
            <CustomerCreate open={open} onClose={() => setOpen(false)} />
            <div className={'flex items-center justify-between flex-row pb-3 flex-end w-full'} title='Users'>
                <h1 className={'p-4 text-lg'}>Customers</h1>
                {user?.role.includes('create_customer') && <div onClick={() => setOpen(true)}
                                                                className="btn btn-primary btn-sm">
                    <PlusIcon className="icon-sm w-6" />
                    <p className={'flex flex-row gap-1'}>New Customer</p>
                </div>}
            </div>

            <ExportDataTable
                data={data?.list ?? []}
                total={data?.total ?? 0}
                columns={columns}
                tableName="Customers"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<div />} />

            <Outlet />
        </>
    );
};
