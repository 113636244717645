import { Tab } from '@headlessui/react';
import {
    KeyIcon,
    PlayCircleIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import profile from '@/assets/images/profile.jpeg';
import { useAppSelector } from '@/store';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { ApikeyList } from '@/app/apikey';

export default function AdminReportsSummary() {
    const tabs = [
        {
            icon: UserIcon,
            title: 'Personal  Information',
            selected: true,
        },
    ];

    const tabItems = [
        <PersonalInformation />,
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Profile |'));
    });

    return (
        <div className="text-gray-700 dark:grey-200">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/dashboard"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Settings</span>
                </li>
            </ul>
            <div className="panel mt-5" id="icon">
                <div className="mb-5 flex items-center justify-between">
                    <h5 className="text-lg font-semibold dark:text-gray-300">
                        Account
                    </h5>
                </div>
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected
                                                    ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#39437b] dark:!border-b-black'
                                                    : 'dark:text-gray-400'
                                            }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {tabItems.map((item, index) => (
                                <Tab.Panel key={index}>
                                    <div className="">{item}</div>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}

function PersonalInformation() {
    const { user } = useAppSelector((state) => state.userState);

    return (
        <div className="pt-5 font-semibold flex flex-col gap-3">
            <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                ACCOUNT INFORMATION
            </div>
            <div className=" font-semibold text-gray-500 p-3">
                <form className="  text-white-dark  rounded-md p-4 mb-5 bg-white dark:bg-black">
                    <div className="flex flex-col sm:flex-row">
                        <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full sm:w-2/12 mb-5">
                            <img
                                src={profile}
                                alt="img"
                                className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto"
                            />
                        </div>
                        <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-5">
                            <div>
                                <label htmlFor="first-name">First Name</label>
                                <input
                                    id="first-name"
                                    type="text"
                                    placeholder="First Name"
                                    value={user?.firstname}
                                    className="form-input"
                                    disabled={true}
                                />
                                {/* {errors.firstName && <p className="text-red-500 text-xs">First name is required</p>} */}
                            </div>
                            <div>
                                <label htmlFor="last-name">Last Name</label>
                                <input
                                    id="last-name"
                                    type="text"
                                    placeholder="Last Name"
                                    value={user?.lastname}
                                    className="form-input"
                                    disabled={true}
                                    // {...register('lastName')}
                                    // onChange={(e) => setLname(e.target.value)}
                                />
                                {/* {errors.lastName && <p className="text-red-500 text-xs">Last name is required</p>} */}
                            </div>
                            {/*<div>*/}
                            {/*    <label htmlFor="name">Role</label>*/}
                            {/*    <input*/}
                            {/*        id="name"*/}
                            {/*        type="text"*/}
                            {/*        placeholder="ex. developer"*/}
                            {/*        defaultValue={user.}*/}
                            {/*        className="form-input"*/}
                            {/*        disabled*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div>
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="ex. Jimmy@gmail.com"
                                    defaultValue={user?.email}
                                    className="form-input"
                                    disabled
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Company</label>
                                <input
                                    id="email"
                                    type="email"
                                    defaultValue={user?.company?.name}
                                    className="form-input"
                                    disabled
                                />
                            </div>
                            <div className="sm:col-span-2 mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function APIKeysE() {
    return (
        <div className="pt-5 font-semibold flex flex-col gap-3">
            <div
                className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                API KEYS
            </div>

            <div className="">
                <ApikeyList />
            </div>
        </div>
    );
}
