import baseApi from '@/core/lib/base';
import { ResponseListModel, ResponseModel } from '@/core/types';
import { InvoiceInput } from '@/app/invoice/components';
import { InvoiceDashboardStatsModel, InvoiceModel } from '@/app/invoice/types';
import { Paginate } from '@/core/hooks';
import { InvoiceAggregatorInput } from '@/app/invoice/components/InvoiceCreateAggregator';
import toast from 'react-hot-toast';

export const invoiceApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		invoiceList: builder.query<
			ResponseListModel<InvoiceModel>,  any>({
			query: paginate => ({
				method: 'GET',
				url: 'invoice/',
				params: paginate,
			}),
            providesTags: ['Invoices'],
		}),

		invoiceRead: builder.query<InvoiceModel, { id: string }>({
			query: ({ id }) => ({
				method: 'GET',
				url: `invoice/${id}`,
			}),
		}),

		invoiceReadGlobal: builder.query<InvoiceModel, { id: string }>({
			query: ({ id }) => ({
				method: 'GET',
				url: `invoice/reference/${id}`,
			}),
		}),

		invoiceDashboardStats: builder.query<
			ResponseModel<InvoiceDashboardStatsModel>,
			null
		>({
			query: () => ({
				method: 'GET',
				url: `invoice/dashboard-stats`,
			}),
		}),

		invoiceApprove: builder.mutation<ResponseModel<null>, string>({
			query: id => ({
				method: 'GET',
				url: `invoice/${id}/approve`,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Invoice approved');
				} catch (error) {}
			},
            invalidatesTags: ['Invoices'],
		}),

		invoiceCancel: builder.mutation<ResponseModel<null>, string>({
			query: id => ({
				method: 'GET',
				url: `invoice/${id}/cancel`,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Invoice canceled');
				} catch (error) {}
			},
            invalidatesTags: ['Invoices'],
		}),

		invoiceCreate: builder.mutation<ResponseModel<InvoiceModel>, InvoiceInput>({
			query: payload => ({
				method: 'POST',
				url: 'invoice/my-invoice',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Invoice created');
				} catch (error: any) {
                    console.log(error);
                }
			},
            invalidatesTags: ['Invoices'],
		}),

		invoiceCreateAggregator: builder.mutation<
			ResponseModel<InvoiceModel>,
			InvoiceAggregatorInput
		>({
			query: payload => ({
				method: 'POST',
				url: 'invoice/aggregator',
				body: payload,
			}),
			onQueryStarted: async (args, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success('Invoice created');
				} catch (error) {}
			},
            invalidatesTags: ['Invoices'],
		}),
	}),
});

export const {
	useInvoiceListQuery,
	useInvoiceReadQuery,
	useInvoiceDashboardStatsQuery,
	useInvoiceApproveMutation,
	useInvoiceCancelMutation,
	useInvoiceCreateMutation,
	useInvoiceCreateAggregatorMutation,
	useInvoiceReadGlobalQuery,
} = invoiceApi;

