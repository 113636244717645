import * as z from 'zod';
import { useEffect } from 'react';
import {Form} from '@/components/form';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import {useCreateRoleMutation,} from "@/app/roles/api";
import { InputField } from '@/components/input';
import toast from 'react-hot-toast';
import { Modal } from '@/components/modal/modal';

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
});

export type CreateRoleInput = z.TypeOf<typeof schema>;

export const CreateRole = (props: { open: boolean, onClose: () => void  }) => {
    const [onUpdate, { isLoading, isSuccess, isError, error }] = useCreateRoleMutation();

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const msg = error as any;
            toast.error(msg.data.message ?? 'Error Occurred');
        }
    }, [isError]);

    return (
        <Modal  title='Create Role' onClose={props.onClose} opened={props.open}>
            <Form<CreateRoleInput, typeof schema>
                schema={schema}
                onSubmit={payload => onUpdate(payload)}
            >
                {({ register, formState }) => (
                    <>
                        <InputField
                            type='text'
                            label='Name'
                            placeholder='Eg: find_roles'
                            loading={isLoading}
                            error={formState.errors.name}
                            registration={register('name')}
                        />

                        <InputField
                            type='text'
                            label='Description'
                            placeholder='Describe role...'
                            loading={isLoading}
                            error={formState.errors.description}
                            registration={register('description')}
                        />

                        <button type='submit' disabled={isLoading} className='btn btn-primary  mt-6 w-full'>
                            <BookmarkIcon className='icon-md w-4' /> Create Role
                        </button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
