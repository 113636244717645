import { RraCodeModel } from '@/app/rra';

import { ResponseListModel } from '@/core/types';
import baseApi from '@/core/lib/base';

export const rraApi = baseApi.injectEndpoints({
	endpoints: builder => ({
		rraCodeList: builder.query<
			ResponseListModel<RraCodeModel>,
			{ code_type: string }
		>({
			query: ({ code_type }) => ({
				method: 'GET',
				url: 'rra/code',
				params: {
					pageNumber: 0,
					pageSize: 1500,
					code_type,
				},
			}),
		}),
	}),
});

export const { useRraCodeListQuery } = rraApi;
