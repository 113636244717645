import * as z from 'zod';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useApikeyCreateMutation } from '@/app/apikey/redux';
import { Button, Modal } from '@mantine/core';
import { InputField } from '@/components/input';
import SelectField from '@/components/select/SelectField';
import { Form } from '@/components/form';

const schema = z.object({
	description: z.string().min(1, 'Description required'),
	isActive: z.string().min(1, 'Status required'),
});

export type ApikeyInput = z.TypeOf<typeof schema>;

export const ApikeyCreate = (props: { open: boolean, onClose: () => void }) => {
	const [onCreate, { isLoading, isSuccess }] = useApikeyCreateMutation();

	useEffect(() => {
		if (isSuccess) {
            props.onClose();
        }
	}, [isSuccess]);

	return (
		<Modal
            opened={props.open}
			title='New Api key'
			// loading={isLoading}
			onClose={props.onClose}
		>
			<Form<ApikeyInput, typeof schema> schema={schema} onSubmit={onCreate}>
				{({ register, formState }) => (
					<>
						<div className='space-y-4'>
							<InputField
								type='text'
								label='Description'
								placeholder='Eg: Project Name'
								loading={isLoading}
								error={formState.errors.description}
								registration={register('description')}

							/>

							<SelectField
								label='Active'
								options={[
									{ value: '1', label : 'Yes' },
									{ value: '0', label : 'No' },
								]}
								// loading={isLoading}
								error={formState.errors.isActive}
								registration={register('isActive')}

							/>
						</div>

						<button className='btn btn-primary mt-6 w-full'>
							<BookmarkIcon className='icon-md w-4 mr-3' /> Create
						</button>
					</>
				)}
			</Form>
		</Modal>
	);
};
